import React, { useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { loginSchema } from '../../../schemas';
import Popup from '../../../base-components/Popup';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const isEmail = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};
function Login() {
  const navigate = useNavigate()
  const [email , setEmail] = useState('')
  const [password , setPassword] = useState('')
  const [showPassword , setShowPassword] = useState(false)
  const [errorMessage , setErrorMessage] = useState('')
  const [isPopupOpen , setIsPopupOpen] = useState(false)

   const handleLogin = async (values , actions) => {
    try {
      const response = await fetch(BASE_URL + `users/loginUser` , {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
        },
        })
      if (response.ok) {
        const responseBody = await response.json();
            console.log(responseBody);
            localStorage.setItem('user-info', JSON.stringify(responseBody));
            sessionStorage.setItem('user-info', JSON.stringify(responseBody));
            setTimeout(async () => {
               navigate('/dashboard');
            }, 1000);
      } else {
        const responseBody = await response.json();
        setErrorMessage(responseBody.message)
        setIsPopupOpen(true)
        setTimeout(() => {
          setIsPopupOpen(false)
        } , 3000)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const {values , errors , touched , handleBlur , handleChange , handleSubmit, resetForm} = useFormik({
    initialValues : {
      email : '',
      password : ''
    },
    validationSchema: loginSchema,
    onSubmit : (values , actions) => {
      handleLogin(values , actions)
    }
  })
  return (
    <div className='w-screen h-screen'>
        <div className='bg-img flex justify-center items-center py-5 md:p-0'>
          <div className='w-full md:w-[500px] md:h-[500px] backdrop-blur-md bg-white/10 rounded-xl text-center p-5'>
              <h1 className='font-bold text-3xl md:text-4xl text-white'>Login</h1>
              <form onSubmit={handleSubmit} className='mt-10 md:mt-16 md:m-5 flex flex-col md:p-5 gap-5 md:gap-10' autoComplete='off'>
                <div>
                    <input type='email' name='email' placeholder='Email'
                    className={'text-white bg-transparent text-center border p-2 rounded-full outline-0 w-full '+ (errors.email && touched.email ? 'border-red-600': '')}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                    {/* <p className='text-red-500 text-left pl-5 pt-1 font-medium'>{errors.email}</p> */}
                    {touched.email && errors.email ? <p className='text-left text-red-500 pl-2 italic'>{errors.email}</p> : null}
                </div>
                <div>
                    <input type={showPassword ? 'text' : 'password'}  name='password' placeholder='Password' 
                    value={values.password}
                    className={'text-white bg-transparent text-center border p-2 rounded-full outline-0 w-full '+ (errors.password && touched.password ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                    <button type='button' className='flex justify-end pr-10 -mt-7 text-white w-full'
                    onClick={()=>setShowPassword(!showPassword)}
                    >
                    {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}/>}
                    </button>
                    {/* <p className='text-red-500 text-left pl-5 pt-4 font-medium'>{errors.password}</p> */}
                    {touched.password && errors.password ? <p className='text-left text-red-500 pl-2 pt-4 italic'>{errors.password}</p> : null}
                    </div>
                <div className='flex items-center justify-between'>
                  <div className='flex gap-2 items-center'>
                    <input type='checkbox' name='remember' className='w-4 h-4'/>
                    <h5 className='text-white font-medium text-sm md:text-base'>Remember Me</h5>
                  </div>
                  <div className=''>
                    <h5 className='text-white font-medium text-sm md:text-base'>Forgot Password?</h5>
                  </div>
                </div>
                <button type='submit' className='bg-white p-2 rounded-full font-bold'>Login</button>
              </form>
              <div className=''>
                <h1 className='text-white'>Don't have an account? <span className='font-bold cursor-pointer' onClick={()=>navigate(`/signup`)}>Register</span></h1>
              </div>
          </div>
        </div>
        {
          isPopupOpen ? (
            <Popup alertType='error' message={errorMessage} onClose={() => setIsPopupOpen(false)}/>
          ) : null
        }
    </div>
  )
}

export default Login