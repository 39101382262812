import { faCircleXmark, faClose } from '@fortawesome/free-solid-svg-icons'
import { faCancel } from '@fortawesome/free-solid-svg-icons/faCancel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import SideMenu from './SideMenu.js'
import { Link, useNavigate } from 'react-router-dom'

function MobileMenu({handleMobileMenu}) {

    const navigate = useNavigate()
    const [selectedMenu , setSelectedMenu] = useState(0)
    const [userName ,setUserName] = useState('')
    const [userEmail , setUserEmail] = useState('')
    const [role , setRole] = useState('')

  useEffect(() => {
    const tabIndex = sessionStorage.getItem('tab-index');
    if (tabIndex !== null) {
      setSelectedMenu(JSON.parse(tabIndex));
    }
      const sessionStr = sessionStorage.getItem('user-info');
      if(sessionStr){
        const session = JSON.parse(sessionStr);
        setUserName(session.user.name)
        setUserEmail(session.user.email)
        setRole(session.user.role)
      }
       else {
        navigate('/login');
      }
  }, [])

  const handleTabChange = (index) => {
    setSelectedMenu(index)
    
    sessionStorage.setItem('tab-index', JSON.stringify(index));
  }

  return (
    <>
        <div className='fixed top-0 left-0 bg-[#022A3A] h-screen w-10/12 z-20 pt-5'>
        {
            SideMenu(role).map((menu , index) => (
            <Link key={index} to={menu.url} className=''>
                <div
                className={'text-white font-medium cursor-pointer border-b border-slate-700 p-3 flex items-center gap-3 ' + (selectedMenu === index ? 'bg-[#03374c] ':'')}
                onClick={() => handleTabChange(index)}
                >
                <span>{menu.icon}</span>
                <h1>{menu.title}</h1>
                </div>
            </Link>
            ))
        }
        </div>
        <FontAwesomeIcon icon={faCircleXmark} className='fixed text-white top-5 right-5 z-30' size='2xl'onClick={handleMobileMenu}/>
    </>
  )
}

export default MobileMenu