import { Box } from '@mui/material'
import { BarChart, Gauge, LineChart, SparkLineChart } from '@mui/x-charts'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Dashboard() {
  const navigate = useNavigate()
  const [userName ,setUserName] = useState('')
  const [userEmail , setUserEmail] = useState('')
  const [role , setRole] = useState('')
  useEffect(()=> {
      const sessionStr = sessionStorage.getItem('user-info');

      if(sessionStr){
        const session = JSON.parse(sessionStr);
        setRole(session.user.role)
        setUserName(session.user.name)
        setUserEmail(session.user.email)
        console.log(session);
    }
       else {
        navigate('/login');
      }
  }, [])
  return (
    <>
      <>
    <div className='flex items-center justify-between m-5'>

      <div className='font-bold text-2xl'>{userName}</div>

        {/* <div className='font-bold text-2xl'>Dashboard</div> */}

        {/* <div className='flex'>
          <Gauge width={100} height={100} value={60} />
          <Gauge width={100} height={100} value={60} startAngle={-90} endAngle={90} />
        </div> */}
    </div>
  
    <div className='flex justify-between items-center my-10'>
      <div className='w-1/2'>
          <BarChart
          series={[
            { data: [35, 44, 24, 34] },
            { data: [51, 6, 49, 30] },
            { data: [15, 25, 30, 50] },
            { data: [60, 50, 15, 25] },
          ]}
          height={290}
          xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
          margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        />
      </div>
      <div className='w-1/2'>
          <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                },
              ]}
              width={500}
              height={300}
          />
      </div>
    </div>
    {/* <div className='flex my-10'>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart
          plotType="bar"
          data={[1, 4, 2, 5, 7, 2, 4, 6]}
          height={100}
        />
      </Box>
    </div> */}
    </>
    </>
  )
}

export default Dashboard