import React, { useEffect } from 'react'
import Home from './Home'
import Header from '../../base-components/Header'
import Footer from '../../base-components/Footer'

function LandingPage() {
  useEffect(()=> {
    sessionStorage.setItem('tab-index', JSON.stringify(0));
  },[])
  return (
    <div className=''>
        <Header/>
        <Home/>
        <Footer/>
    </div>
  )
}

export default LandingPage