import { faCircleCheck, faEye, faPen, faTrash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import CustomModal from '../../../base-components/CustomModal';
import Popup from '../../../base-components/Popup';
import { facilitySchema } from '../../../schemas';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Facilities() {
    const navigate = useNavigate()
    const [facilities , setFacilities] = useState([])
    const [facility , setFacility] = useState({})
    const [locations , setLocations] = useState([])
    const [facilityToDelete , setFacilityToDelete] = useState('')
    const [facilityToUpdate , setFacilityToUpdate] = useState('')
    const [isAddModalOpen , setIsAddModalOpen] = useState(false)
    const [isViewModalOpen , setIsViewModalOpen] = useState(false)
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page , setPage ] = useState(0)
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [role , setRole] = useState('')
    const [location_id , setLocationId] = useState('')
    const [company_id , setCompanyID] = useState('')

    useEffect(()=> {
        const sessionStr = sessionStorage.getItem('user-info');
        if(sessionStr){
          const session = JSON.parse(sessionStr);
          setRole(session.user.role)
          setCompanyID(session.user.company_id)
          setLocationId(session.user.location_id)
        }
         else {
          navigate('/login');
        }
  },[])

    useEffect(()=> {
        getFacilities()
        getLocations()
    },[role])

    function getFacilities () {

        if(!role) return;
        let endpoint = '';
        console.log(role);
        if (role === 'SuperAdmin') {
            endpoint = 'facility/getPackageFacilities';
        } else if (role === 'Admin') {
            endpoint = `facility/getPackageFacilityByCompanyId/${company_id}`;
        } else if (role === 'ClubAdmin') {
            endpoint = `facility/getPackageFacilityByLocationId/${location_id}`;
        }
        fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setFacilities(data.packageFacilities)
            
        })
        .catch((error) => console.log(error));
    }

    function getLocations () {
        if(!role) return;

        let endpoint = '';
        console.log(role);
        if (role === 'SuperAdmin') {
            endpoint = 'locations/getLocations';
        } else if (role === 'Admin') {
            endpoint = `locations/getLocationsByCompanyID/${company_id}`;
        }
        
        fetch(BASE_URL + endpoint)
            .then((response) => response.json())
            .then((data) => {
                setLocations(data.locations)
            })
            .catch((error) => {
                console.log(error)
            } );
    }

    function getFacilityByID (id) {
        fetch(BASE_URL + `facility/getPackageFacilityById/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setFacility(data.packageFacility)
            setValues({
                description: data.packageFacility.description,
            });
        })
        .catch((error) => console.log(error));
    }
    
    const handleAddFacility = async (values, actions) => {
            
            console.log("values:--", values);

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            };

            try {
                const response = await fetch(BASE_URL + `facility/createPackageFacility`, requestOptions);
    
                console.log('Response status:', response.status);
                if (!response.ok) {
                    console.error('Response not ok:', response);
                    setIsFailureAlert(true)
                    failureMessage('Failed to add Facility')
                    // handleFailureOpen('Failed to Add Add');
                } else {
                    const responseData = await response.json();
                    console.log('Response data:', responseData);
                    setIsAddModalOpen(false)
                    actions.resetForm()
                    getFacilities()
                    setIsSuccessAlert(true)
                    setSuccessMessage('Facility added Successfully')
                    setIsPopupOpen(true)
                    setTimeout(() => {
                        setIsPopupOpen(false)
                    } , 5000)
                }
            } catch (error) {
                console.error('Caught error:', error);
                setIsFailureAlert(true) 
                setFailureMessage('Failed to add Facility')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                } , 5000)
            } finally {
                // setLoading(false);
            }
    
    }

    const handleUpdateFacility = async (values, actions) => {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };

        try {
            const response = await fetch(BASE_URL + `facility/updatePackageFacility/${facilityToUpdate}`, requestOptions);

            console.log('Response status:', response.status);
            if (!response.ok) {
                console.error('Response not ok:', response);
                setIsFailureAlert(true)
                setFailureMessage('Failed to Update Facility')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            } else {
                const responseData = await response.json();
                console.log('Response data:', responseData);
                actions.resetForm()
                setIsUpdateModalOpen(false)
                getFacilities()
                setIsSuccessAlert(true)
                setSuccessMessage('Facility Updated Successfully')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            }
        } catch (error) {
            console.error('Caught error:', error);
            setIsFailureAlert(true)
            setFailureMessage('Failed to Update Facility')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        } finally {
            // setLoading(false);
        }

}

    function deleteFacility () {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        };
        fetch(BASE_URL + `facility/deletePackageFacility/${facilityToDelete}` , requestOptions)
        .then((response) => response.json())
        .then((data) => {
            getFacilities()
            setIsDeleteModalOpen(false)
            setIsSuccessAlert(true)
            setSuccessMessage('Facility deleted Successfully')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        })
        .catch((error) => {
            console.log(error)
            setIsFailureAlert(true)
            setFailureMessage('Failed to delete Facility')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        });
    }


    function toggleAddModal() {
        setIsAddModalOpen(!isAddModalOpen)
        resetForm()
    }
    function toggleViewModal() {
        setIsViewModalOpen(!isViewModalOpen)
        resetForm()
    }

    function toggleUpdateModal() {
        setIsUpdateModalOpen(!isUpdateModalOpen)
        resetForm()
    }
    function toggleDeleteModal() {
        setIsDeleteModalOpen(!isDeleteModalOpen)
    }

    function showAddModal(id) {
        setIsAddModalOpen(true);
    }
    function showViewModal(id) {
        getFacilityByID(id);
        setIsViewModalOpen(true);
    }
    function showUpdateModal(id) {

        getFacilityByID(id);
        setFacilityToUpdate(id);
        setIsUpdateModalOpen(true);
    }
    function showDeleteModal(id) {
        setFacilityToDelete(id);
        setIsDeleteModalOpen(true);
    }
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

        const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm , setFieldValue} = useFormik({
            enableReinitialize: true, 
            initialValues: {
                name: '',
                description: '',
                fee: '',
                location_id: role === "ClubAdmin" ? location_id :'',
                company_id: role === "Admin" ? company_id : '',
            },
            validationSchema: facilitySchema,
            onSubmit: (values, actions) => {
                if(isAddModalOpen){
                    handleAddFacility(values, actions); // Call the external function
                } else if(isUpdateModalOpen) {
                    handleUpdateFacility(values, actions);
                }
            },
        })
  return (
    <>
        <div className='flex items-center justify-between m-5'>
            <div className='font-bold text-2xl'>Facilities</div>
            <button className='bg-[#022A3A] text-white font-bold p-2 rounded-lg ' onClick={showAddModal}>Add Facility</button>
        </div>
        {
            facilities && facilities.length !== 0 ? (     
            <Box className="border rounded-lg m-5 animate-fadeIn">
                <Table>
                  <TableHead>
                      <TableRow >
                          <TableCell>Serial</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Fee</TableCell>
                          <TableCell>Actions</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      facilities.map((facility , index) => (
                        <TableRow hover key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{facility.name}</TableCell>
                          <TableCell>{facility.description}</TableCell>
                          <TableCell>{facility.fee}</TableCell>
                          <TableCell>
                            <div className='flex gap-2'>
                              <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                                showViewModal(facility._id);
                              }}>
                                  <FontAwesomeIcon icon={faEye}/> View 
                              </div>
                              <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                                showUpdateModal(facility._id);
                              }}>
                                  <FontAwesomeIcon icon={faPen}/> Update 
                              </div>
                              <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-700'
                              onClick={()=>{
                                showDeleteModal(facility._id);
                              }}
                              >
                                  <FontAwesomeIcon icon={faTrash}/> Delete 
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                      <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={12}
                          count= {facilities.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          slotProps={{
                              select: {
                              inputProps: {
                                  'aria-label': 'rows per page',
                              },
                              native: true,
                              },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                  </TableFooter>
              </Table>
            </Box>
            ) : (
              <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10'>
                <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
              </div>
            )
          }

    <CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
        if(isAddModalOpen){
            toggleAddModal();
        } else if(isUpdateModalOpen) {
            toggleUpdateModal();
        }
    }} size='xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
            {
                isAddModalOpen ? 'Add Facility' : 'Update Facility' 
            }
        </h1>
        <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
            <div>
                <input type='hidden' value={values.company_id}/>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Name:</label>
                    <input type='text' name='name' placeholder='Enter Name' 
                        value={values.name}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.name && touched.name ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p className='text-red-500 pl-2 italic'>{errors.name}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Description:</label>
                    <input type='text' name='description' placeholder='Enter Description' 
                        value={values.description}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.description && touched.description ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.description && errors.description ? <p className='text-red-500 pl-2 italic'>{errors.description}</p> : null}
                </div>
            </div> 
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-1/2'>
                    <label>Fee:</label>
                    <input type='number' name='fee' placeholder='Enter Fee' 
                        value={values.fee}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.fee && touched.fee ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.fee && errors.fee ? <p className='text-red-500 pl-2 italic'>{errors.fee}</p> : null}
                </div>
                {
                    role === "SuperAdmin" || role === "Admin" ? (
                    <div className='w-1/2'>
                        <label>Location:</label>
                        <select name='location_id' placeholder='Select Location'
                            value={values.location_id}
                            className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.location_id && touched.location_id ? 'border-red-600': '')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                                <option>Locations</option>
                            {
                                locations?.map((location , index) => (
                                    <option key={index} value={location._id}>{location.name}</option>
                                ))
                            }
                            
                        </select>
                        {touched.location_id && errors.location_id ? <p className='text-red-500 pl-2 italic'>{errors.location_id}</p> : null}
                    </div>
                    ): null
                }
            </div> 
            <div className='flex justify-between gap-5 mt-5'>
                {
                    role === "SuperAdmin" ? (
                        <div className='w-1/2'>
                            <label>Company:</label>
                            <select name='company_id' placeholder='Select Company'
                                value={values.company_id}
                                className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.company_id && touched.company_id ? 'border-red-600': '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option>Select Company</option>    
                            </select>
                            {touched.company_id && errors.company_id ? <p className='text-red-500 pl-2 italic'>{errors.company_id}</p> : null}
                        </div>
                        ): null
                }
            </div>
            <div className="flex justify-end items-center pt-3 mt-3 border-t">
                <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>Submit</button>
            </div>
        </form>
      </CustomModal>
      
      <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Facility</h1>
        <div className='m-5'>
            {/* <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Name:</h1>
                <span className='text-xl'>{facility.name}</span>
            </div> */}
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Description:</h1>
                <span className='text-xl'>{facility.description}</span>
            </div>
        </div>
      </CustomModal>

      <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
        <div className='m-5'>
            <h2 className="text-xl font-medium">Delete Facility?</h2>
            <p className="text-slate-500 mt-3">Are you sure you want to delete this facility?</p>
            <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteFacility}>Delete</button>
        </div>
      </CustomModal>


      {
        isPopupOpen && (
            <Popup 
             onClose={() => setIsPopupOpen(false)}
             alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
             message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
             />
        )
      }
    </>
  )
}

export default Facilities