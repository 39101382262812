import { Box, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Roles from './Roles';
import Permissions from './Permissions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.prototypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

function Settings() {
    const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
        <div className='m-5 font-bold text-2xl'>Settings</div>
        <Box sx={{ width: '100%' }}>
        <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            centered
        >
            <Tab {...a11yProps(0)} label="Roles" />
            <Tab {...a11yProps(1)} label="Permission" />
        </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className='p-10'>
            <Roles/>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <div className='p-10'>
          <Permissions/>
        </div>
        </TabPanel>
    </>     
  )
}

export default Settings