import React from 'react'

function Footer() {
  return (
    <>
    <div className='w-full h-[500px] bg-[#F7931E] flex items-end'>
        <div className='w-full h-[490px] bg-[#022A3A]'>
            <h1 className='font-bold text-6xl text-white p-5'>Footer</h1>
        </div>
    </div>
    </>
  )
}

export default Footer