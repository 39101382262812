import React from 'react'
import './header.css'
import logo from '../icons/gymx-final-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

function Header() {
    const navigate = useNavigate()
  return (
    <>
    <div className='sticky w-full top-0 z-10'>
        <div className='bg-[#F7931E] w-full h-32 header'>
            <div className='w-full h-28 bg-[#022A3A] header header-2 flex justify-between px-10'>
                <div className='flex items-center'>
                    <img src={logo} className=''></img>
                </div>
                <div className='flex items-center'>
                    <button className='font-bold text-[#022A3A] rounded-full bg-[#F7931E] px-3 py-1'
                    onClick={() => navigate(`/signup`)}
                    >
                        Join Now <FontAwesomeIcon icon={faCircleArrowRight}/>
                    </button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Header