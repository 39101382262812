import { Box, Checkbox, CircularProgress, createTheme, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, ThemeProvider } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { packageSchema } from '../../../schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import CustomModal from '../../../base-components/CustomModal';
import Popup from '../../../base-components/Popup';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select/base';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const theme = createTheme({
    palette: {
      primary: {
        main: '#033b51'
      },
    },
  });
function Packages() {
    const navigate = useNavigate()
    const [packages , setPackages] = useState([])
    const [facilities , setFacilities] = useState([])
    const [pckge , setPckge] = useState({})
    const [packageToDelete , setPackageToDelete] = useState('')
    const [packageToUpdate , setPackageToUpdate] = useState('')
    const [isAddModalOpen , setIsAddModalOpen] = useState(false)
    const [isViewModalOpen , setIsViewModalOpen] = useState(false)
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page , setPage ] = useState(0)
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [loading , setLoading] = useState(false)
    const [role , setRole] = useState('')
    const [locations , setLocations] = useState([])
    const [location , setLocation] = useState()
    const [location_id , setLocationId] = useState('')
    const [company_id , setCompanyID] = useState('')
  
    useEffect(()=> {
        const sessionStr = sessionStorage.getItem('user-info');
        if(sessionStr){
          const session = JSON.parse(sessionStr);
          setRole(session.user.role)
          setCompanyID(session.user.company_id)
          setLocationId(session.user.location_id)
        }
         else {
          navigate('/login');
        }
  },[])

    useEffect(() => {
        getPackages()
        getFacilities()
        getLocations()
}, [role]);

    function getLocations () {
        if(!role) return;

        let endpoint = '';
        console.log(role);
        if (role === 'SuperAdmin') {
            endpoint = 'locations/getLocations';
        } else if (role === 'Admin') {
            endpoint = `locations/getLocationsByCompanyID/${company_id}`;
        }
        
        fetch(BASE_URL + endpoint)
            .then((response) => response.json())
            .then((data) => {
                setLocations(data.locations)
            })
            .catch((error) => {
                console.log(error)
            } );
    }

    function getFacilities () {
        if(!role) return;

        let endpoint = '';
        console.log(role);
        if (role === 'SuperAdmin') {
            endpoint = 'facility/getPackageFacilities';
        } else if (role === 'Admin') {
            endpoint = `facility/getPackageFacilityByCompanyId/${company_id}`;
        }
        
        fetch(BASE_URL + endpoint)
            .then((response) => response.json())
            .then((data) => {
                setFacilities(data.packageFacilities)
                
            })
            .catch((error) => {
                console.log(error)
            } );
    }


    function getLocationId (id) {
        fetch(BASE_URL + `locations/getLocationById/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setLocationId(data.location._id)
            setLocation(data.location)
        })
        .catch((error) => console.log(error));
    }
  
    function getPackages () {
          if(!role) return;
          setLoading(true)
          let endpoint = '';
          console.log(role);
          if (role === 'SuperAdmin') {
              endpoint = `package/getPackages`;
          } else if (role === 'Admin' ) {
              endpoint = `package/getPackagesByCompanyID/${company_id}`;
          } else if(role === 'ClubAdmin') {
            endpoint = `package/getPackagesByLocationID/${location_id}`;
          }
        fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setPackages(data.packages)
            setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
      });
    }
  
    function getPackageByID (id) {
        fetch(BASE_URL + `package/getPackageById/${id}`)
        .then((response) => response.json())
        .then((data) => {
            setPckge(data.packages)
            setValues({
                title: data.packages.title,
                description: data.packages.description,
                price: data.packages.price,
                duration: data.packages.duration,
                locationId: data.packages.locationId,
            });
        })
        .catch((error) => console.log(error));
    }
    
    const handleAddPackage = async (values, actions) => {

        values.company_id = company_id
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            };
    
            try {
                const response = await fetch(BASE_URL + `package/createPackage`, requestOptions);
    
                console.log('Response status:', response.status);
                if (!response.ok) {
                    console.error('Response not ok:', response);
                    setIsFailureAlert(true)
                    setFailureMessage('Failed to add Package')
                    // handleFailureOpen('Failed to Add Add');
                } else {
                    const responseData = await response.json();
                    console.log('Response data:', responseData);
                    setIsAddModalOpen(false)
                    actions.resetForm()
                    getPackages()
                    setIsSuccessAlert(true)
                    setSuccessMessage('Package added Successfully')
                    setIsPopupOpen(true)
                    setTimeout(() => {
                        setIsPopupOpen(false)
                    } , 5000)
                }
            } catch (error) {
                console.error('Caught error:', error);
                setIsFailureAlert(true) 
                setFailureMessage('Failed to add Package')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                } , 5000)
            } finally {
                // setLoading(false);
            }
    
    }
  
    const handleUpdatePackage = async (values, actions) => {
        values.company_id = company_id
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        };
  
        try {
            const response = await fetch(BASE_URL + `package/updatePackage/${packageToUpdate}`, requestOptions);
  
            console.log('Response status:', response.status);
            if (!response.ok) {
                console.error('Response not ok:', response);
                setIsFailureAlert(true)
                setFailureMessage('Failed to Update Package')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            } else {
                const responseData = await response.json();
                console.log('Response data:', responseData);
                actions.resetForm()
                setIsUpdateModalOpen(false)
                getPackages()
                setIsSuccessAlert(true)
                setSuccessMessage('Package Updated Successfully')
                setIsPopupOpen(true)
                setTimeout(() => {
                    setIsPopupOpen(false)
                }, 5000)
            }
        } catch (error) {
            console.error('Caught error:', error);
            setIsFailureAlert(true)
            setFailureMessage('Failed to Update Package')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        } finally {
            // setLoading(false);
        }
  
  }
  
    function deletePackage () {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        };
        fetch(BASE_URL + `package/deletePackage/${packageToDelete}` , requestOptions)
        .then((response) => response.json())
        .then((data) => {
            getPackages()
            setIsDeleteModalOpen(false)
            setIsSuccessAlert(true)
            setSuccessMessage('Package deleted Successfully')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        })
        .catch((error) => {
            console.log(error)
            setIsFailureAlert(true)
            setFailureMessage('Failed to delete Package')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        });
    }
  
  
    function toggleAddModal() {
        setIsAddModalOpen(!isAddModalOpen)
        resetForm()
    }
    function toggleViewModal() {
        setIsViewModalOpen(!isViewModalOpen)
        resetForm()
    }
  
    function toggleUpdateModal() {
        setIsUpdateModalOpen(!isUpdateModalOpen)
        resetForm()
    }
    function toggleDeleteModal() {
        setIsDeleteModalOpen(!isDeleteModalOpen)
    }
  
    function showAddModal(id) {
        setIsAddModalOpen(true);
    }
    function showViewModal(id) {
        getPackageByID(id);
        setIsViewModalOpen(true);
    }
    function showUpdateModal(id) {
  
        getPackageByID(id);
        setPackageToUpdate(id);
        setIsUpdateModalOpen(true);
    }
    function showDeleteModal(id) {
        setPackageToDelete(id);
        setIsDeleteModalOpen(true);
    }
  
    const paginatedPackages = !packages || packages.length === 0 ? [] :
    rowsPerPage === -1
    ? packages // Show all if "All" option is selected
    : packages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
  
      const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm , setFieldValue} = useFormik({
            enableReinitialize: true, 
            initialValues: {
            title: '',
            description: '',
            currency: '',
            duration: '',
            type:'',
            membership_fee: '',
            registration_fee: '',
            start_date: '',
            end_date: '',
            facilities: [],
            locationId: '',
            company_id: '',
        },
        validationSchema: packageSchema(isUpdateModalOpen),
        onSubmit: (values, actions) => {
            console.log('Formik Errors:', errors); 
            if(isAddModalOpen){
                handleAddPackage(values, actions); // Call the external function
            } else if(isUpdateModalOpen) {
                handleUpdatePackage(values, actions);
            }
        },
    })
  return (
    <>
        <div className='flex items-center justify-between m-5'>
            <div className='font-bold text-2xl'>Packages</div>
            {
                role === 'SuperAdmin' || role === 'Admin' ? (
                    <button className='bg-[#033b51] text-white font-bold p-2 rounded-lg ' onClick={showAddModal}>Add Package</button>
                ) : null
            }
        </div>
        {
          loading ? <ThemeProvider theme={theme}><div className='flex justify-center items-center mt-20'><CircularProgress/></div></ThemeProvider>
          :(
            packages && packages.length !== 0 ? (     
            <Box className="border rounded-lg m-5 animate-fadeIn">
                <Table>
                  <TableHead>
                      <TableRow >
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Duration</TableCell>
                          <TableCell>Membership Fee</TableCell>
                          <TableCell>Registration Fee</TableCell>
                          {
                                role === 'SuperAdmin' || role === 'Admin' ? (
                                <TableCell>Actions</TableCell>
                                ) : null
                            }
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      paginatedPackages?.map((pckge , index) => (
                        <TableRow hover key={index}>
                            <TableCell>{pckge.title}</TableCell>
                            <TableCell>{pckge.description}</TableCell>
                            <TableCell>{pckge.duration}</TableCell>
                            <TableCell>{pckge.membership_fee}</TableCell>
                            <TableCell>{pckge.registration_fee}</TableCell>
                            {
                                role === 'SuperAdmin' || role === 'Admin' ? (
                                    <TableCell>
                                    <div className='flex gap-2'>
                                      <div className='font-bold hover:cursor-pointer text-green-700' onClick={()=>{
                                        showViewModal(pckge._id);
                                      }}>
                                          <FontAwesomeIcon icon={faEye}/> View 
                                      </div>
                                      <div className='font-bold hover:cursor-pointer text-[#F7931E]' onClick={()=>{
                                        showUpdateModal(pckge._id);
                                      }}>
                                          <FontAwesomeIcon icon={faPen}/> Update 
                                      </div>
                                      <div className='font-bold hover:cursor-pointer text-red-600'
                                      onClick={()=>{
                                        showDeleteModal(pckge._id);
                                      }}
                                      >
                                          <FontAwesomeIcon icon={faTrash}/> Delete 
                                      </div>
                                    </div>
                                  </TableCell>
                                ) : null
                            }
                        </TableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                      <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={12}
                          count= {packages.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          slotProps={{
                              select: {
                              inputProps: {
                                  'aria-label': 'rows per page',
                              },
                              native: true,
                              },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                  </TableFooter>
              </Table>
            </Box>
            ) : (
              <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10'>
                <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
              </div>
            )
          )
          }
  
    <CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
        if(isAddModalOpen){
            toggleAddModal();
        } else if(isUpdateModalOpen) {
            toggleUpdateModal();
        }
    }} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
            {
                isAddModalOpen ? 'Add Package' : 'Update Package' 
            }
        </h1>
        <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
            <div>
                <input type='hidden' value={values.company_id}/>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Name:</label>
                    <input type='text' name='title' placeholder='Enter Name' 
                        value={values.title}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.title && touched.title ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.title && errors.title ? <p className='text-red-500 pl-2 italic'>{errors.title}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Duration:</label>
                    <select name='duration' placeholder='Enter Duration'
                        value={values.duration}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.duration && touched.duration ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option>Select Duration</option>
                        <option value='monthly'>Monthly</option>
                        <option value='quaterly'>Quaterly</option>
                        <option value='semi-annually'>Semi Annually</option>
                        <option value='annually'>Annually</option>
                    </select>
                    {touched.duration && errors.duration ? <p className='text-red-500 pl-2 italic'>{errors.duration}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Description:</label>
                    <textarea type='text' name='description' placeholder='Enter Description' 
                        value={values.description}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.description && touched.description ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.description && errors.description ? <p className='text-red-500 pl-2 italic'>{errors.description}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Package Type:</label>
                    <select name='type' placeholder='Enter Package Type'
                        value={values.type}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.type && touched.type ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option>Select Package Type</option>
                        <option value='standard'>Standard</option>
                        <option value='group'>Group</option>
                        <option value='addon'>Addon</option>
                    </select>
                    {touched.type && errors.type ? <p className='text-red-500 pl-2 italic'>{errors.type}</p> : null}
                </div>
                {
                    role === 'SuperAdmin' || role === 'Admin' ? (
                        <div className='w-full'>
                            <label>Location:</label>
                            <select name='locationId' placeholder='Enter Location'
                                value={values.locationId}
                                className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.locationId && touched.locationId ? 'border-red-600': '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option>Locations</option>
                                {
                                    role === 'superAdmin' ? (
                                        <option value={location?._id}>{location?.name}</option>
                                    ) : (
                                        locations?.map((location , index) => (
                                            <option key={index} value={location._id}>{location.name}</option>
                                        ))
                                    )
                                    
                                }
                            </select>
                            {touched.locationId && errors.locationId ? <p className='text-red-500 pl-2 italic'>{errors.locationId}</p> : null}
                        </div>
                    ) :null
                }
            </div>
           { 
                values.type === 'addon'&& (
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Start Date:</label>
                    <input type='date' name='start_date' placeholder='Start Date' 
                        value={values.start_date}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.start_date && touched.start_date ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.start_date && errors.start_date ? <p className='text-red-500 pl-2 italic'>{errors.start_date}</p> : null}
                </div>
                <div className='w-full'>
                    <label>End Date:</label>
                    <input type='date' name='end_date' placeholder='End Date' 
                        value={values.end_date}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.end_date && touched.end_date ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.end_date && errors.end_date ? <p className='text-red-500 pl-2 italic'>{errors.end_date}</p> : null}
                </div>
            </div>
            )}
            <Box className="border rounded-lg m-5 animate-fadeIn">
                <h1 className='font-bold text-lg text-slate-500 p-2 border-b'>Facilities:</h1>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell>Facilities Applicable</TableCell>
                            <TableCell>Facility Name</TableCell>
                            <TableCell>Facility Description</TableCell>
                            <TableCell>Facility Fee</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            facilities && facilities.map((facility , index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                    <Checkbox
                                        checked={values.facilities.includes(facility._id)} 
                                        onChange={(event) => {
                                        const isChecked = event.target.checked;
                                        const newFacilities = isChecked
                                            ? [...values.facilities, facility._id] 
                                            : values.facilities.filter((id) => id !== facility._id); 

                                        setFieldValue('facilities', newFacilities); 
                                        }}
                                    />
                                    </TableCell>
                                    <TableCell>{facility.name}</TableCell>
                                    <TableCell>{facility.description}</TableCell>
                                    <TableCell>{facility.fee}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Box>

            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Membership Fee:</label>
                    <input type='text' name='membership_fee' placeholder='Enter Membership Fee' 
                        value={values.membership_fee}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.membership_fee && touched.membership_fee ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.membership_fee && errors.membership_fee ? <p className='text-red-500 pl-2 italic'>{errors.membership_fee}</p> : null}
                </div>
                <div className='w-full'>
                    <label>Registration Fee:</label>
                    <input type='text' name='registration_fee' placeholder='Enter Registration Fee' 
                        value={values.registration_fee}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.registration_fee && touched.registration_fee ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.registration_fee && errors.registration_fee ? <p className='text-red-500 pl-2 italic'>{errors.registration_fee}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-1/2'>
                    <label>Currency:</label>
                    <select name='currency' placeholder='Select Currency'
                        value={values.currency}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.currency && touched.currency ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <option>Select Currency</option>    
                        <option value='usd'>US Dollars (USD)</option>
                        <option value='aed'>UAE Dirhams (AED)</option>
                        <option value='rs'>Pakistani Rupees (RS)</option>
                    </select>
                    {touched.currency && errors.currency ? <p className='text-red-500 pl-2 italic'>{errors.currency}</p> : null}
                </div>
            </div>
            <div className="flex justify-end items-center pt-3 mt-3 border-t">
                <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>Submit</button>
            </div>
        </form>
      </CustomModal>
      
      <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Package</h1>
        <div className='m-5'>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Name:</h1>
                <span className='text-xl'>{pckge.title}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Description:</h1>
                <span className='text-xl'>{pckge.description}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Duration:</h1>
                <span className='text-xl'>{pckge.duration}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Amount:</h1>
                <span className='text-xl'>{pckge.price}</span>
            </div>
        </div>
        
      </CustomModal>
      {
        isPopupOpen && (
            <Popup 
             onClose={() => setIsPopupOpen(false)}
             alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
             message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
             />
                
        )
      }
      
      <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
        <div className='m-5'>
            <h2 className="text-xl font-medium">Delete Package?</h2>
            <p className="text-slate-500 mt-3">Are you sure you want to delete this package?</p>
            <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deletePackage}>Delete</button>
        </div>
      </CustomModal>
    </>
  )
}

export default Packages