import React from 'react'

function Companies() {
  return (
    <>
        <div className='m-5 font-medium text-3xl'>Companies</div>
    </>
  )
}

export default Companies