import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Popup from '../../../base-components/Popup';
import CustomModal from '../../../base-components/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faPen, faTrash, faWarning } from '@fortawesome/free-solid-svg-icons';
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { useFormik } from 'formik';
import { addonSchema } from '../../../schemas';

const BASE_URL = process.env.REACT_APP_BASE_URL;
function Addons() {
    const navigate = useNavigate()
    const [addons , setAddons] = useState([])
    const [addon , setAddon] = useState({})
    const [addonToDelete , setAddonToDelete] = useState('')
    const [addonToUpdate , setAddonToUpdate] = useState('')
    const [isAddModalOpen , setIsAddModalOpen] = useState(false)
    const [isViewModalOpen , setIsViewModalOpen] = useState(false)
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page , setPage ] = useState(0)
    const [countries , setCountries] = useState([])
    const [selectedCountry , setSelectedCountry] = useState('')
    const [selectedState , setSelectedState] = useState('')
    const [selectedCity , setSelectedCity] = useState('')
    const [selectedCountryIsoCode , setSelectedCountryIsoCode] = useState('')
    const [states , setStates] = useState([])
    const [cities , setCities] = useState([])
    const [selectedStateCode , setSelectedStateCode] = useState('')
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)

    useEffect(()=> {
      getAddons()
      getCountries()
  },[])

  function getCountries () {
      fetch(BASE_URL + `country/getCountries`)
      .then((response) => response.json())
      .then((data) => {
          setCountries(data)
          
      })
      .catch((error) => console.log(error));
  
  }

  const handleSelectCountry = (value) => {
      setSelectedCountryIsoCode(value);
      const con = countries.filter(country => country.isoCode === value)
      const countryName = con.length > 0 ? con[0].name : null;
      setSelectedCountry(countryName)
      getStates(value);
      getCities(value)
  }

  const getStates = (value) => {
      fetch(BASE_URL + `country/getStatesById/${value}`)
      .then((response) => response.json())
      .then((data) => {
          setStates(data)
          
      })
      .catch((error) => console.log(error));
  }

  const handleSelectState = (value) => {
      setSelectedStateCode(value);
      const con = states.filter(state => state.isoCode === value)
      const stateName = con.length > 0 ? con[0].name : null;
      setSelectedState(stateName)
  }
  
  const getCities = (value) => {
      fetch(BASE_URL + `country/getCitiesById/${value}`)
      .then((response) => response.json())
      .then((data) => {
          setCities(data)
      })
      .catch((error) => console.log(error));
  }
  


  function getAddons () {
      fetch(BASE_URL + `addon/getPackageAddOns`)
      .then((response) => response.json())
      .then((data) => {
          setAddons(data.packageAddOns)
          
      })
      .catch((error) => console.log(error));
  }

  function getAddonByID (id) {
      fetch(BASE_URL + `addon/getPackageAddOnById/${id}`)
      .then((response) => response.json())
      .then((data) => {
          setAddon(data.packageAddOn)
          setValues({
              title: data.packageAddOn.title,
              price: data.packageAddOn.price,
              
          });
      })
      .catch((error) => console.log(error));
  }
  
  const handleAddAddon = async (values, actions) => {
          const requestOptions = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
          };
  
          try {
              const response = await fetch(BASE_URL + `addon/createPackageAddOn`, requestOptions);
  
              console.log('Response status:', response.status);
              if (!response.ok) {
                  console.error('Response not ok:', response);
                  setIsFailureAlert(true)
                  failureMessage('Failed to add Addon')
                  // handleFailureOpen('Failed to Add Add');
              } else {
                  const responseData = await response.json();
                  console.log('Response data:', responseData);
                  setIsAddModalOpen(false)
                  actions.resetForm()
                  getAddons()
                  setIsSuccessAlert(true)
                  setSuccessMessage('Addon added Successfully')
                  isPopupOpen(true)
                  setTimeout(() => {
                      setIsPopupOpen(false)
                  } , 5000)
              }
          } catch (error) {
              console.error('Caught error:', error);
              setIsFailureAlert(true) 
              setFailureMessage('Failed to add Addon')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              } , 5000)
          } finally {
              // setLoading(false);
          }
  
  }

  const handleUpdateAddon = async (values, actions) => {
      const requestOptions = {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
      };

      try {
          const response = await fetch(BASE_URL + `addon/updatePackageAddOn/${addonToUpdate}`, requestOptions);

          console.log('Response status:', response.status);
          if (!response.ok) {
              console.error('Response not ok:', response);
              setIsFailureAlert(true)
              setFailureMessage('Failed to Update Addon')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          } else {
              const responseData = await response.json();
              console.log('Response data:', responseData);
              actions.resetForm()
              setIsUpdateModalOpen(false)
              getAddons()
              setIsSuccessAlert(true)
              setSuccessMessage('Addon Updated Successfully')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          }
      } catch (error) {
          console.error('Caught error:', error);
          setIsFailureAlert(true)
          setFailureMessage('Failed to Update Addon')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      } finally {
          // setLoading(false);
      }

}

  function deleteAddon () {
      const requestOptions = {
          method: "DELETE",
          headers: {
              "Content-Type": "application/json",
          },
      };
      fetch(BASE_URL + `addon/deletePackageAddOn/${addonToDelete}` , requestOptions)
      .then((response) => response.json())
      .then((data) => {
          getAddons()
          setIsDeleteModalOpen(false)
          setIsSuccessAlert(true)
          setSuccessMessage('Addon deleted Successfully')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      })
      .catch((error) => {
          console.log(error)
          setIsFailureAlert(true)
          setFailureMessage('Failed to delete Addon')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      });
  }


    function toggleAddModal() {
      setIsAddModalOpen(!isAddModalOpen)
      resetForm()
  }
  function toggleViewModal() {
      setIsViewModalOpen(!isViewModalOpen)
      resetForm()
  }

  function toggleUpdateModal() {
      setIsUpdateModalOpen(!isUpdateModalOpen)
      resetForm()
  }
  function toggleDeleteModal() {
      setIsDeleteModalOpen(!isDeleteModalOpen)
  }

  function showAddModal(id) {
      setIsAddModalOpen(true);
  }
  function showViewModal(id) {
      getAddonByID(id);
      setIsViewModalOpen(true);
  }
  function showUpdateModal(id) {

      getAddonByID(id);
      setAddonToUpdate(id);
      setIsUpdateModalOpen(true);
  }
  function showDeleteModal(id) {
      setAddonToDelete(id);
      setIsDeleteModalOpen(true);
  }
  

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm} = useFormik({
      initialValues: {
          title: '',
          price: '',
      },
      validationSchema: addonSchema,
      onSubmit: (values, actions) => {
          if(isAddModalOpen){
              handleAddAddon(values, actions); // Call the external function
          } else if(isUpdateModalOpen) {
              handleUpdateAddon(values, actions);
          }
      },
  })
  return (
    <>
    <div className='flex items-center justify-between m-5'>
        <div className='font-bold text-2xl'>Addons</div>
        <button className='bg-[#022A3A] text-white font-bold p-2 rounded-lg ' onClick={showAddModal}>Add Addon</button>
    </div>
    {
        addons && addons.length !== 0 ? (     
        <Box className="border rounded-lg m-5 animate-fadeIn">
            <Table>
              <TableHead>
                  <TableRow >
                      <TableCell>Title</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Actions</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {
                  addons.map((addon , index) => (
                    <TableRow hover key={index}>
                      <TableCell>{addon.title}</TableCell>
                      <TableCell>{addon.price}</TableCell>
                      <TableCell>
                        <div className='flex gap-2'>
                          <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                            showViewModal(addon._id);
                          }}>
                              <FontAwesomeIcon icon={faEye}/> View 
                          </div>
                          <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                            showUpdateModal(addon._id);
                          }}>
                              <FontAwesomeIcon icon={faPen}/> Update 
                          </div>
                          <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-700'
                          onClick={()=>{
                            showDeleteModal(addon._id);
                          }}
                          >
                              <FontAwesomeIcon icon={faTrash}/> Delete 
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
              <TableFooter>
                  <TableRow>
                  <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={12}
                      count= {addons.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                          select: {
                          inputProps: {
                              'aria-label': 'rows per page',
                          },
                          native: true,
                          },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      />
                  </TableRow>
              </TableFooter>
          </Table>
        </Box>
        ) : (
          <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10'>
            <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
          </div>
        )
      }

<CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
    if(isAddModalOpen){
        toggleAddModal();
    } else if(isUpdateModalOpen) {
        toggleUpdateModal();
    }
}} size='2xl'>
    <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
        {
            isAddModalOpen ? 'Add Addon' : 'Update Addon' 
        }
    </h1>
    <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
        <div>
            <input type='hidden' value={values.company_id}/>
        </div>
        <div className='flex justify-between gap-5'>
            <div className='w-full'>
                <label>Title:</label>
                <input type='text' name='title' placeholder='Enter Title' 
                    value={values.title}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.title && touched.title ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.title && errors.title ? <p className='text-red-500 pl-2 italic'>{errors.title}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Price:</label>
                <input type='number' name='price' placeholder='Enter Price'
                    value={values.price}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.price && touched.price ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.price && errors.price ? <p className='text-red-500 pl-2 italic'>{errors.price}</p> : null}
            </div>
        </div>
        <div className="flex justify-end items-center pt-3 mt-3 border-t">
            <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>Submit</button>
        </div>
    </form>
  </CustomModal>
  
  <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
    <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Addon</h1>
    <div className='m-5'>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Title:</h1>
            <span className='text-xl'>{addon.title}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Phone:</h1>
            <span className='text-xl'>{addon.price}</span>
        </div>
    </div>
    
  </CustomModal>

  <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
    <div className='m-5'>
        <h2 className="text-xl font-medium">Delete Addon?</h2>
        <p className="text-slate-500 mt-3">Are you sure you want to delete this addon?</p>
        <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteAddon}>Delete</button>
    </div>
  </CustomModal>
{
    isPopupOpen && (
        <Popup 
            onClose={() => setIsPopupOpen(false)}
            alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
            message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
        />
    )
  }

      
    </>
  )
}

export default Addons