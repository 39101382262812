import * as Yup from 'yup'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const timeRules = /^([01]\d|2[0-3]):([0-5]\d)$/;

export const locationSchema = (isUpdateModalOpen) => Yup.object().shape({
    name: Yup.string().min(4 , 'Name must be atleast 4 charachters').required('Name is required.'),
    email: Yup.string().email('Invalid email.').required('Email is required.'),
    phone_number: Yup.number().positive().integer().required('Phone is required.'),
    description: Yup.string().required('Description is required.'),
    password: isUpdateModalOpen ? Yup.string().nullable() 
      : Yup.string()
        .min(6, 'Password must be at least 6 characters.')
        .matches(passwordRules , {message: 'Password must contain atleast 1 uppercase, 1 lowercase, 1 number and 1 special character.'})
        .required('Password is required.'),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is Required'),
    postal_code: Yup.number().min(4).required('Postal Code is required'),
    opening_hours: Yup.string().matches(timeRules , {message: 'Please enter opening time in HH:MM format'}).required('Opening Hour is required.'),
    closing_hours: Yup.string().matches(timeRules , {message: 'Please enter closing time in HH:MM format'}).required('Closing Hour is required.'),
  });

  export const memberSchema = (isUpdateModalOpen) => {
    return Yup.object().shape({
      name: Yup.string().min(4 , 'Name must be atleast 4 charachters').required('Name is required.'),
      email: Yup.string().email('Invalid email.').required('Email is required.'),
      password: isUpdateModalOpen ? Yup.string().nullable() 
      : Yup.string()
        .min(6, 'Password must be at least 6 characters.')
        .matches(passwordRules , {message: 'Password must contain atleast 1 uppercase, 1 lowercase, 1 number and 1 special character.'})
        .required('Password is required.'),
      phone: Yup.number().positive().integer().required('Phone is required.'),
      address: Yup.string().required('Address is required'),
      country: Yup.string().required('Country is required'),
      state: Yup.string().required('State is required'),
      city: Yup.string().required('City is Required'),
      postal_code: Yup.number().min(4).required('Postal Code is required'),
      // company_id:Yup.string().required('Company is required'),
      location_id:Yup.string().required('Location is required'),
      package_id:Yup.string().required('Package is required'),
      // role:Yup.string().required('Role is required'),
    });
  }

  export const facilitySchema = Yup.object().shape({
    description: Yup.string().required('Description is required.'),
  });

  export const addonSchema = Yup.object().shape({
    title: Yup.string().min(4 , 'Title must be atleast 4 charachters').required('Title is required.'),
    price: Yup.number().positive().integer().required('Price is required.'),
  });

  export const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email.').required('Email is required.'),
    password: Yup.string()
      .required('Password is required.'),
  });

  export const roleSchema = Yup.object().shape({
    description: Yup.string().required('Description is required.'),
    name: Yup.string().min(4 , 'Name must be atleast 4 charachters').required('Name is required.'),
  });

  export const permissionSchema = Yup.object().shape({
    description: Yup.string().required('Description is required.'),
    name: Yup.string().min(4 , 'Name must be atleast 4 charachters').required('Name is required.'),
  });

  export const packageSchema = (isUpdateModalOpen) => {
    return Yup.object().shape({
      title: Yup.string().min(4 , 'Name must be atleast 4 charachters').required('Name is required.'),
      membership_fee: Yup.number().positive().integer().required('Amount is required.'),
      registration_fee: Yup.number().positive().integer().required('Registration Fee is required.'),
      description: Yup.string().required('Description is required.'),
      duration: Yup.string().required('Duration is Required'),
      // start_date: Yup.date()
        // .required('Date is required'),
      // end_date: Yup.date()
      // .required('Date is required')
      // .min(new Date(), 'Date cannot be in the past'),
      locationId:Yup.string().required('Location is required'),
    });
  }



//   confirm_password: Yup.string()
//       .min(6, 'Password must be at least 6 characters.')
//       .matches(passwordRules , {message: 'Please create stronger password'})
//       .required('Password is required.'),


// email: Yup.string().email('Invalid email.').required('Email is required.'),
