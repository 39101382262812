import React, { Component, useEffect, useRef, useState } from 'react'
import SideMenuTabs from './SideMenuTabs'
import logo from '../icons/gymx-final-white.png'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import Dashboard from '../pages/dashboard/Dashboard'
import mobileLogo from '../icons/favicon-128.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsStaggered, faCircleUser, faUser } from '@fortawesome/free-solid-svg-icons'
import MobileMenu from './MobileMenu'

function Main() {
    const [userName ,setUserName] = useState('')
    const [userEmail , setUserEmail] = useState('')
    const [mobileMenu , setMobileMenu] = useState(false)
    const navigate = useNavigate()
    const [isMenuOpen , setIsMenuOpen] = useState(false)
    const [role , setRole] = useState('')
    const menuRef = useRef(null);
    
    useEffect(()=> {
        const sessionStr = sessionStorage.getItem('user-info');

        if(sessionStr){
          const session = JSON.parse(sessionStr);
          setUserName(session.user.name)
          setUserEmail(session.user.email)
          setRole(session.user.role)
        }
         else {
          navigate('/login');
        }
    }, [])

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsMenuOpen(false);
        }
      };

    useEffect(() => {
        if (isMenuOpen) {
          document.addEventListener('click', handleClickOutside);
        } else {
          document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
          };
        }, [isMenuOpen]);

        const handleOpenMenu = (event) => {
            event.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
        }

        const handleLogout = () => {
            sessionStorage.removeItem('user-info');
            localStorage.removeItem('user-info');
            sessionStorage.removeItem('tab-index');
            navigate('/login');
        }

    const handleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    }
  return (
    <>
        <div className='bg-[#022A3A] py-2'>
            <div className='md:flex'>
                <div className='md:w-2/12 md:h-screen'>
                    <div className='flex justify-between md:justify-center items-center border-b border-slate-700 p-2 mb-5'>
                        <img src={logo} className='w-36 hidden md:block hover:cursor-pointer' onClick={() => navigate('/')} />
                        <img src={mobileLogo} className='w-8 md:hidden hover:cursor-pointer' onClick={() => navigate('/')}/>
                        <FontAwesomeIcon icon={faBarsStaggered} className='mr-3 text-white md:hidden' onClick={handleMobileMenu}/>
                    </div>
                    <div className='hidden md:block'>
                        <SideMenuTabs/>
                    </div>
                </div>
                <div className='md:w-10/12 bg-white h-screen rounded-3xl overflow-y-auto '>
                    <div className='flex items-center justify-between p-4 text-sm '>
                        <div>
                            <a href='/' className='text-[#022A3A] font-medium'>Application {">"}</a>
                            <a href='/dashboard' className=''><span className='text-[#022A3A] font-medium'>{">"}</span> Dashboard</a>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faCircleUser} className='text-4xl text-slate-500 hover:cursor-pointer' onClick={handleOpenMenu}/>
                        </div>
                    </div>
                    <div className='border-b border-black mx-5 opacity-10'></div>
                    <Outlet/>
                </div>
            </div>
            <div ref={menuRef}
                className={'w-[250px] pb-5 bg-[#022A3A] absolute top-16 right-4 rounded-xl text-sm text-white transition-opacity duration-500 '+ (isMenuOpen ? 'opacity-100 z-10 visible' : 'opacity-0 z-[-1] invisible')}>
                <div className='font-bold mx-3 my-2'>
                    <h1 className=''>{userName}</h1>
                    <h1 className='font-medium mx-2'>{userEmail}</h1>
                </div>
                    <div className='border-b border-white opacity-25'></div>
                <div className='p-2 mx-2 my-1 hover:bg-[#03394f] hover:cursor-pointer rounded'>
                    <h1 className='font-medium '>Settings</h1>
                </div>
                    <div className='border-b border-white opacity-25 '></div>
                <div className='p-2 mx-2 my-1 hover:bg-[#03394f] hover:cursor-pointer rounded'>
                    <h1 className=' font-medium' onClick={handleLogout}>Logout</h1>
                </div>
                    <div className='border-b border-white opacity-25 '></div>
            </div>
            <div className='md:hidden'>
            {
                mobileMenu ? (
                    <>
                        <MobileMenu handleMobileMenu = {handleMobileMenu}/>
                        <div className='overlay'></div>
                    </>
                ): null
            }
            </div>
        </div>
    </>
  )
}

export default Main