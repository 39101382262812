import React from 'react'
import './landingPage.css'

function Home() {
  return (
    <div className='home-image -mt-[100px] mb-[100px]'>
        <div className='headerBox'>
        <h2>Ultimate Gym Management Solution</h2>
        <p> <span className='font-bold'> GymXS</span> is for gym owners like you. You want to streamline your business with powerful membership software that minimizes hassle, increases member retention, and helps both you and your members to achieve your goals</p>
        <div className='text-center mt-10'><a type="button" class="bg-[#F7931E] text-white font-medium p-3 rounded-lg "> SIGN UP NOW</a> </div>
        </div>
    </div>
  )
}

export default Home