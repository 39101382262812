import React from 'react'
import { useRoutes } from 'react-router-dom'
import LandingPage from '../pages/landingPage/LandingPage'
import Main from '../layout/Main'
import Dashboard from '../pages/dashboard/Dashboard'
import Companies from '../pages/dashboard/companies/Companies'
import Login from '../pages/auth/login/Login'
import Logout from '../pages/auth/Logout'
import SignUp from '../pages/auth/SignUp'
import Settings from '../pages/settings/Settings'
import Members from '../pages/dashboard/members/Members'
import Locations from '../pages/dashboard/locations/Locations'
import Facilities from '../pages/dashboard/facilities/Facilities'
import Addons from '../pages/dashboard/addons/Addons'
import Voucher from '../pages/dashboard/ledgers/vouchers/Voucher'
import Ledgers from '../pages/dashboard/ledgers/Ledgers'
import Packages from '../pages/dashboard/packages/Packages'

function Router() {
    const routes = [
        {
            path: "/",
            element: <LandingPage/>,
        },
        {
            path: 'login',
            element: <Login/>
        },
        {
            path: 'logout',
            element: <Logout/>
        },
        {
            path: 'signup',
            element: <SignUp/>
        },
        {
            path: '/',
            element: <Main/>,
            children: [
                {
                    path: 'dashboard',
                    element: <Dashboard/>
                },
                {
                    path: 'companies',
                    element: <Companies/>
                },
                {
                    path: 'packages',
                    element: <Packages/>
                },
                {
                    path: 'voucher/:id',
                    element: <Voucher/>
                },
                {
                    path: 'ledgers',
                    element: <Ledgers/>
                },
                {
                    path: 'members',
                    element: <Members/>
                },
                {
                    path: 'settings',
                    element: <Settings/>
                },
                {
                    path: 'locations',
                    element: <Locations/>
                },
                {
                    path: 'facilities',
                    element: <Facilities/>
                },
                {
                    path: 'addons',
                    element: <Addons/>
                },
            ]
        }
    ]

  return(
    useRoutes(routes)
  ) 
}

export default Router