import { Box, Button, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Popup from '../../../base-components/Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faEyeSlash, faPen, faTrash, faUser, faWarning } from '@fortawesome/free-solid-svg-icons';
import CustomModal from '../../../base-components/CustomModal';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import { memberSchema } from '../../../schemas';
// import './members.css'

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Members() {

  const navigate = useNavigate()
  const selectPhotoRef = useRef(null)
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

    const [cameraStream, setCameraStream] = useState(null);
    const [members , setMembers] = useState([])
    const [active_members , setActiveMembers] = useState([])
    const [inactive_members , setInactiveMembers] = useState([])
    const [member , setMember] = useState({})
    const [role , setRole] = useState('')
    const [locations , setLocations] = useState([])
    const [showPassword , setShowPassword] = useState(false)
    const [memberToDelete , setMemberToDelete] = useState('')
    const [memberToUpdate , setMemberToUpdate] = useState('')
    const [isAddModalOpen , setIsAddModalOpen] = useState(false)
    const [isViewModalOpen , setIsViewModalOpen] = useState(false)
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page , setPage ] = useState(0)
    const [countries , setCountries] = useState([])
    const [selectedCountry , setSelectedCountry] = useState('')
    const [selectedState , setSelectedState] = useState('')
    const [selectedCity , setSelectedCity] = useState('')
    const [selectedCountryIsoCode , setSelectedCountryIsoCode] = useState('')
    const [states , setStates] = useState([])
    const [cities , setCities] = useState([])
    const [selectedStateCode , setSelectedStateCode] = useState('')
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [tabType , setTabType] = useState('all')
    const [packages , setPackages] = useState([])
    const [member_package_amount , setMemberPackageAmount] = useState('')
    const [company_id , setCompanyID] = useState('')
    const [location_id , setLocationID] = useState('')

    useEffect(()=> {
        const sessionStr = sessionStorage.getItem('user-info');
        if(sessionStr){
          const session = JSON.parse(sessionStr);
          setRole(session.user.role)
          setCompanyID(session.user.company_id)
          setLocationID(session.user.location_id)
        }
         else {
          navigate('/login');
        }
  },[])

  // Separate useEffect to call getMembers once role is set
useEffect(() => {
    if (role) {
        getMembers();
        getCountries()
        getLocations()
        getPackages()
    }
}, [role]);

useEffect(() => {
    if (videoRef.current && cameraStream) {
      // This ensures that the video element is available before assigning the stream
      videoRef.current.srcObject = cameraStream;
  
      // Wait for the video to load and then play
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
      };
    }
  }, [cameraStream]); // Re-run when cameraStream changes
  

  function getPackages() {
    let endpoint = '';
    console.log(role);
    if (role === 'SuperAdmin') {
        endpoint = `package/getPackages`;
    } else if (role === 'Admin') {
        endpoint = `package/getPackagesByCompanyID/${company_id}`;
    } else if(role === 'ClubAdmin') {
        endpoint = `package/getPackagesByLocationID/${location_id}`;
    }
    fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setPackages(data.packages)
        })
        .catch((error) => console.log(error));
  }

  function getLocations() {
    let endpoint = '';
    console.log(role);
    if (role === 'SuperAdmin') {
        endpoint = 'locations/getLocations';
    } else if (role === 'Admin') {
        endpoint = `locations/getLocationsByCompanyID/${company_id}`;
    }
    fetch(BASE_URL + endpoint)
        .then((response) => response.json())
        .then((data) => {
            setLocations(data.locations)
        })
        .catch((error) => console.log(error));
  }

  function getCountries () {
      fetch(BASE_URL + `country/getCountries`)
      .then((response) => response.json())
      .then((data) => {
          setCountries(data)
          
      })
      .catch((error) => console.log(error));
  
  }

  const addVoucher = async (data) => {
    try {
        const response = await fetch(BASE_URL + `voucher/createVoucher` , {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json',
          },
      })
  
        if (response.ok) {
          const responseBody = await response.json();
             console.log(responseBody.newVoucher);
             
        } else {
          const responseBody = await response.json();
        }
      } catch (error) {
        console.error('Error:', error);
      }
}

  const handleSelectCountry = (value) => {
      setSelectedCountryIsoCode(value);
      const con = countries.filter(country => country.isoCode === value)
      const countryName = con.length > 0 ? con[0].name : null;
      setSelectedCountry(countryName)
      getStates(value);
      getCities(value)
  }

  const getStates = (value) => {
      fetch(BASE_URL + `country/getStatesById/${value}`)
      .then((response) => response.json())
      .then((data) => {
          setStates(data)
          
      })
      .catch((error) => console.log(error));
  }

  const handleSelectState = (value) => {
      setSelectedStateCode(value);
      const con = states.filter(state => state.isoCode === value)
      const stateName = con.length > 0 ? con[0].name : null;
      setSelectedState(stateName)
  }
  
  const getCities = (value) => {
      fetch(BASE_URL + `country/getCitiesById/${value}`)
      .then((response) => response.json())
      .then((data) => {
          setCities(data)
      })
      .catch((error) => console.log(error));
  }

  function getMemberPackage (id) {
    fetch(BASE_URL + `package/getPackageById/${id}`)
    .then((response) => response.json())
    .then((data) => {
        setMemberPackageAmount(data.packages.fee)
        console.log(data.packages.fee);
        
    })
    .catch((error) => console.log(error));
}
  


function getMembers() {
    let endpoint = '';
    console.log(role);
    if (role === 'SuperAdmin') {
        endpoint = 'members/get-all-members';
    } else if (role === 'Admin') {
        endpoint = `members/getMembersByCompanyID/${company_id}`;
    } else if (role === 'ClubAdmin') {
        endpoint = `members/getMembersByLocationID/${location_id}`;
    }
    if (endpoint) {
        fetch(BASE_URL + endpoint)
            .then((response) => response.json())
            .then((data) => {
                setMembers(data.members);
                setActiveMembers(data.members.filter(member => member.is_active === true));
                setInactiveMembers(data.members.filter(member => member.is_active === false));
            })
            .catch((error) => console.log(error));
    }
}

  function getMemberByID (id) {
      fetch(BASE_URL + `members//get-member/${id}`)
      .then((response) => response.json())
      .then((data) => {
          setMember(data.member)
          setValues(data.member);
      })
      .catch((error) => console.log(error));
  }
  
  const handleAddMember = async (values, actions) => {
        values.company_id = company_id    

          const requestOptions = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
          };
  
          try {
              const response = await fetch(BASE_URL + `members/create-member`, requestOptions);
  
              console.log('Response status:', response.status);
              if (!response.ok) {
                  console.error('Response not ok:', response);
                  setIsFailureAlert(true)
                  failureMessage('Failed to add Member')
                  // handleFailureOpen('Failed to Add Add');
              } else {
                  const responseData = await response.json();
                  console.log('Response data:', responseData);
                  setIsAddModalOpen(false)
                  actions.resetForm()
                  addVoucher({
                    memberId: responseData.Member._id,
                    description: `Voucher for ${responseData.Member.name}`,
                    amount: member_package_amount,
                    locationId: responseData.Member.location_id,
                    company_id: responseData.Member.company_id
                  })
                  getMembers()
                  setIsSuccessAlert(true)
                  setSuccessMessage('Member added Successfully')
                  setIsPopupOpen(true)
                  setTimeout(() => {
                      setIsPopupOpen(false)
                  } , 5000)
              }
          } catch (error) {
              console.error('Caught error:', error);
              setIsFailureAlert(true) 
              setFailureMessage('Failed to add Member')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              } , 5000)
          } finally {
              // setLoading(false);
          }
  
  }

  const handleUpdateMember = async (values, actions) => {
      const requestOptions = {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
      };

      try {
          const response = await fetch(BASE_URL + `members/update-member/${memberToUpdate}`, requestOptions);

          console.log('Response status:', response.status);
          if (!response.ok) {
              console.error('Response not ok:', response);
              setIsFailureAlert(true)
              setFailureMessage('Failed to Update Member')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          } else {
              const responseData = await response.json();
              console.log('Response data:', responseData);
              actions.resetForm()
              setIsUpdateModalOpen(false)
              getMembers()
              setIsSuccessAlert(true)
              setSuccessMessage('Member Updated Successfully')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          }
      } catch (error) {
          console.error('Caught error:', error);
          setIsFailureAlert(true)
          setFailureMessage('Failed to Update Member')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      } finally {
          // setLoading(false);
      }

}

  function deleteMember () {
      const requestOptions = {
          method: "DELETE",
          headers: {
              "Content-Type": "application/json",
          },
      };
      fetch(BASE_URL + `members/delete-member/${memberToDelete}` , requestOptions)
      .then((response) => response.json())
      .then((data) => {
          getMembers()
          setIsDeleteModalOpen(false)
          setIsSuccessAlert(true)
          setSuccessMessage('Member deleted Successfully')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      })
      .catch((error) => {
          console.log(error)
          setIsFailureAlert(true)
          setFailureMessage('Failed to delete Member')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      });
  }

  const handleStatusChange = async (id , is_active) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({is_active : !is_active}),
    };

    try {
        const response = await fetch(BASE_URL + `members/update-member/${id}`, requestOptions);

        console.log('Response status:', response.status);
        if (!response.ok) {
            console.error('Response not ok:', response);
            setIsFailureAlert(true)
            setFailureMessage('Failed to Update Member')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        } else {
            const responseData = await response.json();
            console.log('Response data:', responseData);
            setIsUpdateModalOpen(false)
            getMembers()
            setIsSuccessAlert(true)
            setSuccessMessage('Member Updated Successfully')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        }
    } catch (error) {
        console.error('Caught error:', error);
        setIsFailureAlert(true)
        setFailureMessage('Failed to Update Member')
        setIsPopupOpen(true)
        setTimeout(() => {
            setIsPopupOpen(false)
        }, 5000)
    } finally {
        // setLoading(false);
    }

  }


    function toggleAddModal() {
      setIsAddModalOpen(!isAddModalOpen)
      handleCloseCamera()
      resetForm()
  }
  function toggleViewModal() {
      setIsViewModalOpen(!isViewModalOpen)
      resetForm()
  }

  function toggleUpdateModal() {
      setIsUpdateModalOpen(!isUpdateModalOpen)
      handleCloseCamera()
      resetForm()
  }
  function toggleDeleteModal() {
      setIsDeleteModalOpen(!isDeleteModalOpen)
  }

  function showAddModal(id) {
      setIsAddModalOpen(true);
  }
  function showViewModal(id) {
      getMemberByID(id);
      setIsViewModalOpen(true);
  }
  function showUpdateModal(id) {

      getMemberByID(id);
      setMemberToUpdate(id);
      setIsUpdateModalOpen(true);
  }
  function showDeleteModal(id) {
      setMemberToDelete(id);
      setIsDeleteModalOpen(true);
  }

  const handleSelectPhotoClick = (e) => {
    e.preventDefault();
    if (selectPhotoRef.current) {
      selectPhotoRef.current.click();
    } else {
      console.error('selectPhotoRef is not properly initialized.');
    }
  };

  const handleTakePhotoClick = async (e) => {
    e.preventDefault();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
  
      if (videoRef.current) {
        console.log("stream");
        videoRef.current.srcObject = stream;
  
        videoRef.current.oncanplay = () => {
          console.log("cameraStream1");
          videoRef.current.play();
        };
      } else {
        console.error('Video element is not properly initialized');
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const handleCloseCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
    }
  };
  

  const handleCapturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(
        videoRef.current,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight
      );

      // Get the photo data as a URL
      const photoData = canvasRef.current.toDataURL("image/png");
      setFieldValue("photo", photoData);

      // Stop the camera stream
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
        setCameraStream(null);
      }
    }
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm , setFieldValue} = useFormik({
      initialValues: {
        id:'',
        name: '',
        phone: '',
        photo: '',
        email:'',
        password: '',
        address: '',
        country: '',
        state:'',
        city: '',
        postal_code: '',
        cnic:'',
        age: '',
        gender: 'male',
        height:'',
        height_units: 'ft',
        weight: '',
        weight_units: 'kg',
        emergency_contact: '',
        emergency_contact_no: '',
        disease: '',
        company_id: '',
        location_id: role === 'ClubAdmin' ? location_id : '' ,
        package_id:'',
        role: 'User',
      },
      enableReinitialize: true,
      validationSchema: memberSchema(isUpdateModalOpen),
      onSubmit: (values, actions) => {
          if(isAddModalOpen){
              handleAddMember(values, actions); // Call the external function
          } else if(isUpdateModalOpen) {
              handleUpdateMember(values, actions);
          }
      },
  })

  const handleRipple = (e) => {
    const button = e.currentTarget;

    const ripple = document.createElement('span');
    
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    
    ripple.style.width = ripple.style.height = `${diameter}px`;
    ripple.style.left = `${e.clientX - button.offsetLeft - radius}px`;
    ripple.style.top = `${e.clientY - button.offsetTop - radius}px`;
    
    ripple.classList.add('ripple');
    
    const existingRipple = button.querySelector('.ripple');
    if (existingRipple) {
      existingRipple.remove();
    }

    button.appendChild(ripple);
  };
  return (
    <>
    <div className='flex items-center justify-between m-5'>
        <div className='font-bold text-2xl'>Members</div>
        <button className='ripple-button bg-[#022A3A] text-white font-bold p-2 rounded-lg relative overflow-hidden active:bg-[#033b51]'
         onClick={showAddModal} 
        //  onMouseDown={handleRipple}
         >Add Member</button>
    </div>

    <div className='m-5 flex justify-center items-center'>
        <button className={'border border-slate-300 text-slate-600 font-bold p-2 rounded-s-full transition-colors duration-300 ' + (tabType === 'active' ? 'bg-slate-200' : '')} onClick={() => setTabType('active')}>Active Members</button>
        <button className={'border border-slate-300 text-slate-600 font-bold p-2 transition-colors duration-300 ' + (tabType === 'all' ? 'bg-slate-200' : '')} onClick={() => setTabType('all')}>All Members</button>
        <button className={'border border-slate-300 text-slate-600 font-bold p-2 rounded-e-full transition-colors duration-300 ' + (tabType === 'inactive' ? 'bg-slate-200' : '')} onClick={() => setTabType('inactive')}>Inactive Members</button>
    </div>
    {
        tabType === 'all' ? (
            members && members.length !== 0 ? (     
                <Box className="border rounded-lg m-5 animate-fadeIn">
                    <div style={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Club</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        members.map((member , index) => (
                            <TableRow hover key={index}>
                            <TableCell>{member.name}</TableCell>
                            <TableCell>{member.email}</TableCell>
                            <TableCell>{member.address}</TableCell>
                            <TableCell>{member.phone}</TableCell>
                            <TableCell>{member.location_id}</TableCell>
                            <TableCell>
                                <span 
                                  className={'p-2 rounded-full text-white hover:cursor-pointer ' + (member.is_active ? 'bg-green-700' : 'bg-red-700')}
                                  onClick={()=>handleStatusChange(member._id , member.is_active)}
                                  >
                                    {member.is_active ? 'active' : 'inactive'}
                                    </span>
                            </TableCell>
                            <TableCell>
                                <div className='flex gap-2'>
                                <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                                    showViewModal(member._id);
                                }}>
                                    <FontAwesomeIcon icon={faEye}/> View 
                                </div>
                                <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                                    showUpdateModal(member._id);
                                }}>
                                    <FontAwesomeIcon icon={faPen}/> Update 
                                </div>
                                <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-700'
                                onClick={()=>{
                                    showDeleteModal(member._id);
                                }}
                                >
                                    <FontAwesomeIcon icon={faTrash}/> Delete 
                                </div>
                                </div>
                            </TableCell>
                            </TableRow>
                        ))
                        }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={12}
                                count= {members.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    </div>
                </Box>
        ) : (
    <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10 animate-fadeIn'>
        <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
    </div>
    )
        ) : (
            tabType === 'active' ? (
                active_members && active_members.length !== 0 ? (     
                    <Box className="border rounded-lg m-5 animate-fadeIn">
                        <Table>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Club</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            active_members.map((member , index) => (
                                <TableRow hover key={index}>
                                <TableCell>{member.name}</TableCell>
                                <TableCell>{member.email}</TableCell>
                                <TableCell>{member.address}</TableCell>
                                <TableCell>{member.phone}</TableCell>
                                <TableCell>{member.location_id}</TableCell>
                                <TableCell>
                                <span 
                                  className={'p-2 rounded-full text-white hover:cursor-pointer ' + (member.is_active ? 'bg-green-700' : 'bg-red-700')}
                                  onClick={()=>handleStatusChange(member._id , member.is_active)}
                                >
                                    {member.is_active ? 'active' : 'inactive'}
                                </span>                               
                                </TableCell>
                                <TableCell>
                                    <div className='flex gap-2'>
                                    <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                                        showViewModal(member._id);
                                    }}>
                                        <FontAwesomeIcon icon={faEye}/> View 
                                    </div>
                                    <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                                        showUpdateModal(member._id);
                                    }}>
                                        <FontAwesomeIcon icon={faPen}/> Update 
                                    </div>
                                    <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-700'
                                    onClick={()=>{
                                        showDeleteModal(member._id);
                                    }}
                                    >
                                        <FontAwesomeIcon icon={faTrash}/> Delete 
                                    </div>
                                    </div>
                                </TableCell>
                                </TableRow>
                            ))
                            }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={12}
                                    count= {members.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    slotProps={{
                                        select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Box>
            ) : (
        <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10 animate-fadeIn'>
            <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
        </div>
        )
            ) : (
                tabType === 'inactive' ? (
                    inactive_members && inactive_members.length !== 0 ? (     
                        <Box className="border rounded-lg m-5 animate-fadeIn">
                            <Table>
                                <TableHead>
                                    <TableRow >
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Club</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                inactive_members.map((member , index) => (
                                    <TableRow hover key={index}>
                                    <TableCell>{member.name}</TableCell>
                                    <TableCell>{member.email}</TableCell>
                                    <TableCell>{member.address}</TableCell>
                                    <TableCell>{member.phone}</TableCell>
                                    <TableCell>{member.location_id}</TableCell>
                                    <TableCell>
                                    <span 
                                    className={'p-2 rounded-full text-white hover:cursor-pointer ' + (member.is_active ? 'bg-green-700' : 'bg-red-700')}
                                    onClick={()=>handleStatusChange(member._id , member.is_active)}
                                    >
                                            {member.is_active ? 'active' : 'inactive'}
                                    </span>
                                    </TableCell>
                                    <TableCell>
                                        <div className='flex gap-2'>
                                        <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                                            showViewModal(member._id);
                                        }}>
                                            <FontAwesomeIcon icon={faEye}/> View 
                                        </div>
                                        <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                                            showUpdateModal(member._id);
                                        }}>
                                            <FontAwesomeIcon icon={faPen}/> Update 
                                        </div>
                                        <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-700'
                                        onClick={()=>{
                                            showDeleteModal(member._id);
                                        }}
                                        >
                                            <FontAwesomeIcon icon={faTrash}/> Delete 
                                        </div>
                                        </div>
                                    </TableCell>
                                    </TableRow>
                                ))
                                }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={12}
                                        count= {members.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        slotProps={{
                                            select: {
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Box>
                ) : (
            <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10 animate-fadeIn'>
                <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
            </div>
            )
                ): null
            )
        )
    }

<CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
    if(isAddModalOpen){
        toggleAddModal();
    } else if(isUpdateModalOpen) {
        toggleUpdateModal();
    }
}} size='2xl'>
    <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
        {
            isAddModalOpen ? 'Add Member' : 'Update Member' 
        }
    </h1>
    <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
        <h1 className='font-bold text-lg text-slate-500 pb-2 border-b'>Member Info:</h1>
        <div className="flex justify-between gap-5 mt-4">
            <div className='w-full'>
                <label>Profile Photo:</label>
                <div className='w-full flex gap-2 mx-5 mt-3'>
                    <button className='border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0' onClick={handleSelectPhotoClick}>Select Photo</button>
                    {!cameraStream && (
                    <button
                        className="border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0"
                        onClick={handleTakePhotoClick}
                    >
                        Take Photo
                    </button>
                    )}
                     {cameraStream && (
                        <button
                        type="button"
                        className="border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-red-100 hover:bg-red-200 px-2 py-1 outline-0"
                        onClick={handleCloseCamera}
                        >
                        Close Camera
                        </button>
                    )}
                    {/* <button className='border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0' onClick={handleTakePhotoClick}>Take Photo</button> */}
                </div>
                <input
                    type="file"
                    name="photo"
                    className="hidden"
                    ref={selectPhotoRef}
                    onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                        const reader = new FileReader();
                        reader.onload = () => setFieldValue("photo", reader.result);
                        reader.readAsDataURL(file);
                        }
                    }}
                    onBlur={handleBlur}
                    />
                

                {cameraStream && (
                    <div className="flex flex-col items-center">
                        <video ref={videoRef} className="border mb-2" />
                        <button
                        type="button"
                        className="border border-slate-300 hover:border-slate-400 hover:text-slate-500 bg-slate-100 hover:bg-slate-200 px-2 py-1 outline-0"
                        onClick={handleCapturePhoto}
                        >
                        Capture Photo
                        </button>
                    </div>
                    )}

                  

                    {values.photo && (
                    <div className="mt-4">
                        <label>Captured Photo:</label>
                        <img
                        src={values.photo}
                        alt="Captured"
                        className="border mt-2"
                        />
                    </div>
                    )}

                    {/* Hidden canvas for photo capture */}
                    <canvas ref={canvasRef} className="hidden" />
                {touched.photo && errors.photo ? <p className='text-red-500 pl-2 italic'>{errors.photo}</p> : null}
            </div>
        </div>

        <div className='flex justify-between gap-5 mt-4'>
            <div className='w-full'>
                <label>Name:</label>
                <input type='text' name='name' placeholder='Enter Name' 
                    value={values.name}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.name && touched.name ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.name && errors.name ? <p className='text-red-500 pl-2 italic'>{errors.name}</p> : null}
            </div>
            <div className='w-full'>
                <label>Phone:</label>
                <input type='number' name='phone' placeholder='Enter Phone'
                    value={values.phone}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.phone && touched.phone ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.phone && errors.phone ? <p className='text-red-500 pl-2 italic'>{errors.phone}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Age:</label>
                <input type='number' name='age' placeholder='Enter Age' 
                    value={values.age}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.age && touched.age ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.age && errors.age ? <p className='text-red-500 pl-2 italic'>{errors.age}</p> : null}
            </div>
            <div className='w-full'>
                <label>CNIC:</label>
                <input type='number' name='cnic' placeholder='Enter CNIC'
                    value={values.cnic}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.cnic && touched.cnic ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.cnic && errors.cnic ? <p className='text-red-500 pl-2 italic'>{errors.cnic}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Emergancy Contact Name:</label>
                <input type='text' name='emergency_contact' placeholder='Enter Emergancy Contact Name' 
                    value={values.emergency_contact}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.emergency_contact && touched.emergency_contact ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.emergency_contact && errors.emergency_contact ? <p className='text-red-500 pl-2 italic'>{errors.emergency_contact}</p> : null}
            </div>
            <div className='w-full'>
                <label>Emergancy Contact No:</label>
                <input type='number' name='emergency_contact_no' placeholder='Enter Emergancy Contact No' 
                    value={values.emergency_contact_no}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.emergency_contact_no && touched.emergency_contact_no ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.emergency_contact_no && errors.emergency_contact_no ? <p className='text-red-500 pl-2 italic'>{errors.emergency_contact_no}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Gender:</label>
                <select name='gender' placeholder='Select Gender'
                    value={values.gender}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.gender && touched.gender ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value='male' >Male</option>
                    <option value='female' >Female</option>
                    <option value='other' >Other</option>
                 
                </select>
                {touched.gender && errors.gender ? <p className='text-red-500 pl-2 italic'>{errors.gender}</p> : null}
            </div>
            <div className='w-full'>
                <label>Disease/Medical Condition:</label>
                <input type='text' name='disease' placeholder='Enter Disease/Medical Condition' 
                    value={values.disease}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.disease && touched.disease ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.disease && errors.disease ? <p className='text-red-500 pl-2 italic'>{errors.disease}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Height:</label>
                <input type='number' name='height' placeholder='Enter Height' 
                    value={values.height}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.height && touched.height ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.height && errors.height ? <p className='text-red-500 pl-2 italic'>{errors.height}</p> : null}
            </div>
            <div className='w-full'>
                <label>Height Units:</label>
                <select name='height_units' placeholder='Select Height Units'
                    value={values.height_units}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.height_units && touched.height_units ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value='ft' >Feet (ft)</option>
                    <option value='cm' >Centimeter (cm)</option>
                 
                </select>
                {touched.height_units && errors.height_units ? <p className='text-red-500 pl-2 italic'>{errors.height_units}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Weight:</label>
                <input type='number' name='weight' placeholder='Enter Weight' 
                    value={values.weight}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.weight && touched.weight ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.weight && errors.weight ? <p className='text-red-500 pl-2 italic'>{errors.weight}</p> : null}
            </div>
            <div className='w-full'>
                <label>Weight Units:</label>
                <select name='weight_units' placeholder='Select Weight Unit'
                    value={values.weight_units}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.weight_units && touched.weight_units ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value='kg' >Kilogram (kg)</option>
                    <option value='lb' >Pound (lb)</option>
                 
                </select>
                {touched.weight_units && errors.weight_units ? <p className='text-red-500 pl-2 italic'>{errors.weight_units}</p> : null}
            </div>
        </div>
        <h1 className='font-bold text-lg text-slate-500 pb-2 border-b mt-5'>Member Address & Location Details:</h1>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Address:</label>
                <input type='text' name='address' placeholder='Enter Address'
                    value={values.address}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.address && touched.address ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.address && errors.address ? <p className='text-red-500 pl-2 italic'>{errors.address}</p> : null}
            </div>
           
            <div className='w-full'>
                <label className=''>Postal Code:</label>
                <input type='text' name='postal_code' placeholder='Enter Potal Code' 
                    value={values.postal_code}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.postal_code && touched.postal_code ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.postal_code && errors.postal_code ? <p className='text-red-500 pl-2 italic'>{errors.postal_code}</p> : null}
            </div>
        </div>
        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Country:</label>
                <select name='country' placeholder='Enter Country'
                    value={values.country}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.country && touched.country ? 'border-red-600': '')}
                    onChange={(e) => {
                        handleChange(e); 
                        getStates(e.target.value);
                        getCities(e.target.value);
                    }}
                    onBlur={handleBlur}
                >
                    <option value='' >Country</option>
                    {
                       countries?.map((country , index) => (
                            <option key={index} value={country.isoCode} >{country.name}</option>
                       ))
                    }
                </select>
                {touched.country && errors.country ? <p className='text-red-500 pl-2 italic'>{errors.country}</p> : null}
            </div>
            <div className='w-full'>
                <label>State:</label>
                <select name='state' placeholder='Enter State'
                    value={values.state}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.state && touched.state ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value=''>State</option>
                    {
                       states?.map((state , index) => (
                            <option key={index} value={state.isoCode} >{state.name}</option>
                       ))
                    }
                </select>
                {touched.state && errors.state ? <p className='text-red-500 pl-2 italic'>{errors.state}</p> : null}
            </div>
            <div className='w-full'>
                <label>City:</label>
                <select name='city' placeholder='Enter City' 
                    value={values.city}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.city && touched.city ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value=''>City</option>
                    {
                       cities?.map((city , index) => (
                            <option key={index} value={city.name} >{city.name}</option>
                       ))
                    }
                </select>
                {touched.city && errors.city ? <p className='text-red-500 pl-2 italic'>{errors.city}</p> : null}
            </div>
        </div>

        <h1 className='font-bold text-lg text-slate-500 pb-2 border-b mt-5'>Package Details:</h1>

        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Package:</label>
                <select name='package_id'
                 value={values.package_id}
                 className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.package_id && touched.package_id ? 'border-red-600': '')}
                 onChange={(e) => {
                    handleChange(e)
                    getMemberPackage(e.target.value)
                }}
                onBlur={handleBlur}
                 >
                    <option value=''>Select Package</option>
                    {
                        packages?.map((pckg , index)=>(
                            <option key={index} value={pckg._id}>{pckg.title}</option>
                        ))
                    }
                </select>
                {(touched.package_id && errors.package_id) ? <p className='text-red-500 pl-2 italic'>{errors.package_id}</p> : null}
            </div>
        </div>

        <h1 className='font-bold text-lg text-slate-500 pb-2 border-b mt-5'>Member Credentials</h1>

        <div className='flex justify-between gap-5 mt-5'>
            <div className='w-full'>
                <label>Email:</label>
                <input type='email' name='email' placeholder='Enter Email' 
                    value={values.email}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.email && touched.email ? 'border-red-600': '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.email && errors.email ? <p className='text-red-500 pl-2 italic'>{errors.email}</p> : null}
            </div>
            {
                role !== 'ClubAdmin' ? (
                <div className='w-full'>
                    <label>Location:</label>
                    <select name='location_id'
                    value={values.location_id}
                    className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.location_id && touched.location_id ? 'border-red-600': '')}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    >
                        <option value=''>Select Location</option>
                        {
                            locations?.map((location , index)=>(
                                <option key={index} value={location._id}>{location.name}</option>
                            ))
                        }
                    </select>
                    {(touched.location_id && errors.location_id) ? <p className='text-red-500 pl-2 italic'>{errors.location_id}</p> : null}
                </div>
                ) : (
                    <input type='hidden' name='location_id' value={values.location_id}/>
                )
            }
            
        </div>
        {
            isAddModalOpen ? (
                <div className='flex justify-between gap-5 mt-5'>
                    <div className='w-full'>
                        <label>Password:</label>
                        <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password'
                            value={values.password}
                            className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.password && touched.password ? 'border-red-600': '')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <button type='button' className='w-full flex justify-end pr-10 -mt-7 text-slate-500'
                        onClick={()=>setShowPassword(!showPassword)}
                        >
                        {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}/>}
                        </button>
                        {touched.password && errors.password ? <p className='text-red-500 pl-2 mt-3 italic'>{errors.password}</p> : null}
                    </div>
                </div>
            ) : null
        }
        <div className="flex justify-end items-center pt-3 mt-3 border-t">
            <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>Submit</button>
        </div>
    </form>
  </CustomModal>
  
  <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
    <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Member</h1>
    <div className='m-5'>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Name:</h1>
            <span className='text-xl'>{member.name}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Email:</h1>
            <span className='text-xl'>{member.email}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Address:</h1>
            <span className='text-xl'>{member.address}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Phone:</h1>
            <span className='text-xl'>{member.phone}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Postal Code:</h1>
            <span className='text-xl'>{member.postal_code}</span>
        </div>
        <div className='flex justify-start items-center gap-5'>
            <h1 className='font-medium text-xl'>Location:</h1>
            <span className='text-xl'>{member.location_id}</span>
        </div>
    </div>
    
  </CustomModal>

  <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
    <div className='m-5'>
        <h2 className="text-xl font-medium">Delete Member?</h2>
        <p className="text-slate-500 mt-3">Are you sure you want to delete this member?</p>
        <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteMember}>Delete</button>
    </div>
  </CustomModal>
{
    isPopupOpen && (
      <Popup 
      onClose={() => setIsPopupOpen(false)}
      alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
      message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
      />
    )
  }

      
    </>
  )
}

export default Members