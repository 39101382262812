// import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './base-components/Footer';
import Header from './base-components/Header';
import LandingPage from './pages/landingPage/LandingPage';
import Main from './layout/Main';

function App() {
  return (
    <>
    </>
  );
}

export default App;
