import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TablePaginationActions from '../../base-components/TablePaginationActions';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { roleSchema } from '../../schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomModal from '../../base-components/CustomModal';
import Popup from '../../base-components/Popup';
const BASE_URL = process.env.REACT_APP_BASE_URL;

function Roles() {
  const navigate = useNavigate()
  const [roles , setRoles] = useState([])
  const [role , setRole] = useState({})
  const [roleToDelete , setRoleToDelete] = useState('')
  const [roleToUpdate , setRoleToUpdate] = useState('')
  const [isAddModalOpen , setIsAddModalOpen] = useState(false)
  const [isViewModalOpen , setIsViewModalOpen] = useState(false)
  const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
  const [isUpdateModalOpen , setIsUpdateModalOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page , setPage ] = useState(0)
  const [isSuccessAlert , setIsSuccessAlert] = useState(false)
  const [isFaliureAlert , setIsFailureAlert] = useState(false)
  const [successMessage , setSuccessMessage] = useState('')
  const [failureMessage , setFailureMessage] = useState('')
  const [isPopupOpen , setIsPopupOpen] = useState(false)

  useEffect(()=> {
      getRoles()
  },[])

  function getRoles () {
      fetch(BASE_URL + `roles/getRoles`)
      .then((response) => response.json())
      .then((data) => {
          setRoles(data.roles)
          
      })
      .catch((error) => console.log(error));
  }

  function getRoleByID (id) {
      fetch(BASE_URL + `roles/getRoleById/${id}`)
      .then((response) => response.json())
      .then((data) => {
          setRole(data.role)
          setValues({
              name: data.role.name,
              description: data.role.description,
          });
      })
      .catch((error) => console.log(error));
  }
  
  const handleAddRole = async (values, actions) => {
          const requestOptions = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
          };
  
          try {
              const response = await fetch(BASE_URL + `roles/createRole`, requestOptions);
  
              console.log('Response status:', response.status);
              if (!response.ok) {
                  console.error('Response not ok:', response);
                  setIsFailureAlert(true)
                  failureMessage('Failed to add Role')
                  // handleFailureOpen('Failed to Add Add');
              } else {
                  const responseData = await response.json();
                  console.log('Response data:', responseData);
                  setIsAddModalOpen(false)
                  actions.resetForm()
                  getRoles()
                  setIsSuccessAlert(true)
                  setSuccessMessage('Role added Successfully')
                  isPopupOpen(true)
                  setTimeout(() => {
                      setIsPopupOpen(false)
                  } , 5000)
              }
          } catch (error) {
              console.error('Caught error:', error);
              setIsFailureAlert(true) 
              setFailureMessage('Failed to add Role')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              } , 5000)
          } finally {
              // setLoading(false);
          }
  
  }

  const handleUpdateRole = async (values, actions) => {
      const requestOptions = {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
      };

      try {
          const response = await fetch(BASE_URL + `roles/updateRole/${roleToUpdate}`, requestOptions);

          console.log('Response status:', response.status);
          if (!response.ok) {
              console.error('Response not ok:', response);
              setIsFailureAlert(true)
              setFailureMessage('Failed to Update Role')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          } else {
              const responseData = await response.json();
              console.log('Response data:', responseData);
              actions.resetForm()
              setIsUpdateModalOpen(false)
              getRoles()
              setIsSuccessAlert(true)
              setSuccessMessage('Role Updated Successfully')
              setIsPopupOpen(true)
              setTimeout(() => {
                  setIsPopupOpen(false)
              }, 5000)
          }
      } catch (error) {
          console.error('Caught error:', error);
          setIsFailureAlert(true)
          setFailureMessage('Failed to Update Role')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      } finally {
          // setLoading(false);
      }

}

  function deleteRole () {
      const requestOptions = {
          method: "DELETE",
          headers: {
              "Content-Type": "application/json",
          },
      };
      fetch(BASE_URL + `roles/deleteRole/${roleToDelete}` , requestOptions)
      .then((response) => response.json())
      .then((data) => {
          getRoles()
          setIsDeleteModalOpen(false)
          setIsSuccessAlert(true)
          setSuccessMessage('Role deleted Successfully')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      })
      .catch((error) => {
          console.log(error)
          setIsFailureAlert(true)
          setFailureMessage('Failed to delete Role')
          setIsPopupOpen(true)
          setTimeout(() => {
              setIsPopupOpen(false)
          }, 5000)
      });
  }


  function toggleAddModal() {
      setIsAddModalOpen(!isAddModalOpen)
      resetForm()
  }
  function toggleViewModal() {
      setIsViewModalOpen(!isViewModalOpen)
      resetForm()
  }

  function toggleUpdateModal() {
      setIsUpdateModalOpen(!isUpdateModalOpen)
      resetForm()
  }
  function toggleDeleteModal() {
      setIsDeleteModalOpen(!isDeleteModalOpen)
  }

  function showAddModal(id) {
      setIsAddModalOpen(true);
  }
  function showViewModal(id) {
      getRoleByID(id);
      setIsViewModalOpen(true);
  }
  function showUpdateModal(id) {

      getRoleByID(id);
      setRoleToUpdate(id);
      setIsUpdateModalOpen(true);
  }
  function showDeleteModal(id) {
      setRoleToDelete(id);
      setIsDeleteModalOpen(true);
  }
  

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

  const {values , touched , errors , handleBlur , handleChange , handleSubmit , setValues , resetForm} = useFormik({
      initialValues: {
          name: '',
          description: '',
      },
      validationSchema: roleSchema,

      onSubmit: (values, actions) => {
          if(isAddModalOpen){
              handleAddRole(values, actions); // Call the external function
          } else if(isUpdateModalOpen) {
              handleUpdateRole(values, actions);
          }
      },
  })
  return (
    <>
        <div className='flex items-center justify-between m-5'>
            <div className='font-bold text-2xl'>Roles</div>
            <button className='bg-[#022A3A] text-white font-bold p-2 rounded-lg ' onClick={showAddModal}>Add Role</button>
        </div>
        {
            roles && roles.length !== 0 ? (     
            <Box className="border rounded-lg m-5 animate-fadeIn">
                <Table>
                  <TableHead>
                      <TableRow >
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Actions</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      roles.map((role , index) => (
                        <TableRow hover key={index}>
                          <TableCell>{role.name}</TableCell>
                          <TableCell>{role.description}</TableCell>
                          <TableCell>
                            <div className='flex gap-2'>
                              <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-green-700' onClick={()=>{
                                showViewModal(role._id);
                              }}>
                                  <FontAwesomeIcon icon={faEye}/> View 
                              </div>
                              <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-[#F7931E]' onClick={()=>{
                                showUpdateModal(role._id);
                              }}>
                                  <FontAwesomeIcon icon={faPen}/> Update 
                              </div>
                              <div className='text-slate-700 font-bold hover:cursor-pointer hover:text-red-700'
                              onClick={()=>{
                                showDeleteModal(role._id);
                              }}
                              >
                                  <FontAwesomeIcon icon={faTrash}/> Delete 
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                      <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={12}
                          count= {roles.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          slotProps={{
                              select: {
                              inputProps: {
                                  'aria-label': 'rows per page',
                              },
                              native: true,
                              },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                  </TableFooter>
              </Table>
            </Box>
            ) : (
              <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10'>
                <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
              </div>
            )
          }

    <CustomModal isModal={isAddModalOpen || isUpdateModalOpen} handleModal = {()=> {
        if(isAddModalOpen){
            toggleAddModal();
        } else if(isUpdateModalOpen) {
            toggleUpdateModal();
        }
    }} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>
            {
                isAddModalOpen ? 'Add Role' : 'Update Role' 
            }
        </h1>
        <form onSubmit={handleSubmit} className='text-slate-400 m-5' autoComplete='off'>
            <div>
                <input type='hidden' value={values.company_id}/>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Name:</label>
                    <input type='text' name='name' placeholder='Enter Name' 
                        value={values.name}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.name && touched.name ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p className='text-red-500 pl-2 italic'>{errors.name}</p> : null}
                </div>
            </div>
            <div className='flex justify-between gap-5 mt-5'>
                <div className='w-full'>
                    <label>Description:</label>
                    <input type='text' name='description' placeholder='Enter Description' 
                        value={values.description}
                        className={'border border-slate-300 bg-slate-100 w-full p-2 rounded-xl outline-0 '+ (errors.description && touched.description ? 'border-red-600': '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.description && errors.description ? <p className='text-red-500 pl-2 italic'>{errors.description}</p> : null}
                </div>
            </div>
            <div className="flex justify-end items-center pt-3 mt-3 border-t">
                <button type='submit' className='bg-blue-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-blue-700'>Submit</button>
            </div>
        </form>
      </CustomModal>
      
      <CustomModal isModal={isViewModalOpen} handleModal = {toggleViewModal} size='2xl'>
        <h1 className='font-bold text-3xl text-slate-500 p-5 border-b mx-2'>View Role</h1>
        <div className='m-5'>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Name:</h1>
                <span className='text-xl'>{role.name}</span>
            </div>
            <div className='flex justify-start items-center gap-5'>
                <h1 className='font-medium text-xl'>Description:</h1>
                <span className='text-xl'>{role.description}</span>
            </div>
        </div>
      </CustomModal>

      <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
        <div className='m-5'>
            <h2 className="text-xl font-medium">Delete Role?</h2>
            <p className="text-slate-500 mt-3">Are you sure you want to delete this role?</p>
            <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteRole}>Delete</button>
        </div>
      </CustomModal>


      {
        isPopupOpen && (
            <Popup 
             onClose={() => setIsPopupOpen(false)}
             alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
             message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
             />
        )
      }
    </>
  )
}

export default Roles