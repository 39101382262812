import { Component } from "react";
import Dashboard from "../pages/dashboard/Dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard } from "@fortawesome/free-solid-svg-icons/faDashboard";
import { faIndustry } from "@fortawesome/free-solid-svg-icons/faIndustry";
import { faBook, faBoxesPacking, faCirclePlus, faCity, faCog, faLandmark, faLocation, faUser } from "@fortawesome/free-solid-svg-icons";

const SideMenu = (role) => [
    // {
    //     url: '/companies',
    //     title: 'Companies',
    //     icon: <FontAwesomeIcon icon={faCity}/>,
    // },
    {
        url: '/dashboard',
        title: 'Dashboard',
        icon: <FontAwesomeIcon icon={faDashboard}/>,
        ignore: false,
    },
    {
        url: '/locations',
        title: 'Locations',
        icon: <FontAwesomeIcon icon={faLocation}/>,
        ignore: (role === 'SuperAdmin' || role === 'Admin' ? false : true),

    },
    {
        url: '/members',
        title: 'Members',
        icon: <FontAwesomeIcon icon={faUser}/>,
        ignore: (role === 'SuperAdmin' || role === 'Admin' || role === 'ClubAdmin' ? false : true),
    },
    // {
    //     url: '/familyMembers',
    //     title: 'Family Members',
    //     icon: <FontAwesomeIcon icon={faUser}/>,
    //     ignore: (role === 'member' ? false : true),
    // },
    {
        url: '/ledgers',
        title: 'Ledgers',
        icon: <FontAwesomeIcon icon={faBook}/>,
        ignore: false,
    },
    {
        url: '/facilities',
        title: 'Facilities',
        icon: <FontAwesomeIcon icon={faLandmark}/>,
        ignore: (role === 'SuperAdmin' || role === 'Admin' || role === 'ClubAdmin' ? false : true),
    },
    {
        url: '/packages',
        title: 'Packages',
        icon: <FontAwesomeIcon icon={faBoxesPacking}/>,
        ignore: (role === 'SuperAdmin' || role === 'Admin' || role === 'ClubAdmin' ? false : true),
    },
    {
        url: '/addons',
        title: 'Package Addons',
        icon: <FontAwesomeIcon icon={faCirclePlus}/>,
        ignore: (role === 'SuperAdmin' || role === 'Admin' || role === 'ClubAdmin' ? false : true),
    },
    {
        url: '/settings',
        title: 'Settings',
        icon: <FontAwesomeIcon icon={faCog}/>,
        ignore: (role === 'SuperAdmin' ? false : true),
    },
]

export default SideMenu