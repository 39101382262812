import { faCircleCheck, faClose, faWarning, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function Popup({onClose , alertType, message}) {
  return (
    <div className='fixed top-5 right-5 max-w-xs  rounded-lg border border-slate-300 shadow-lg p-4 z-50 bg-slate-100 animate-fadeIn'>
        <FontAwesomeIcon icon={faClose} className='float-right text-red-600 hover:cursor-pointer' onClick={onClose}/>
        <div className='flex justify-between items-center gap-2 mt-5'>
            <FontAwesomeIcon icon={alertType === 'success' ? faCircleCheck : faWarning} className={'text-5xl ' + (alertType === 'success' ? 'text-green-600' : 'text-red-600')}/>
            <h1 className='text-slate-400 font-medium italic mt-2 text-center'>{message}</h1>
        </div>
    </div>
  )
}

export default Popup