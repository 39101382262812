import React from 'react';

function CustomModal({ isModal, handleModal, size, children }) {
  const sizeClasses = {
    sm: 'sm:max-w-sm',
    md: 'sm:max-w-md',
    lg: 'sm:max-w-lg',
    xl: 'sm:max-w-xl',
    '2xl':'sm:max-w-2xl',
    '3xl':'sm:max-w-3xl',
    '4xl':'sm:max-w-4xl',
    '5xl':'sm:max-w-5xl',
    '6xl':'sm:max-w-6xl',

  };

  // Function to handle clicks outside the modal
  const handleOverlayClick = (e) => {
    // Check if the click target is the overlay
    if (e.target === e.currentTarget) {
      handleModal();
    }
  };

  return (
    <div
      className={`fixed inset-0 z-10 w-screen overflow-y-auto transition-opacity duration-300 ease-out ${
        isModal ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-out ${
          isModal ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
        aria-hidden="true"
        onClick={handleOverlayClick} // Add onClick to the outer div
      ></div>
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300 ease-out sm:my-8 sm:w-full ${
            sizeClasses[size] || 'sm:max-w-md' // Default to medium size
          } ${isModal ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
        >
          <div className="bg-white">
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomModal;
