import React, { useState } from 'react'
import './login/login.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../base-components/Popup';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const isEmail = (email) => {
 return  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

function SignUp() {
  const navigate = useNavigate()
  const [company_name , setCompanyName] = useState('')
  const [email , setEmail] = useState('')
  const [password , setPassword] = useState('')
  const [confirmPassword , setConfirmPassword] = useState('')
  const [roleID , setRoleID] = useState('66e428bb5fd52ccafe6cd15a')
  const [showPassword , setShowPassword] = useState(false)
  const [showConfirmPassword , setShowConfirmPassword] = useState(false)
  const [errors , setErrors] = useState({})
  const [isPopupOpen , setIsPopupOpen] = useState(false)
  const [message , setMessage] = useState('')
  const [alertType , setAlertType] = useState('')


  async function handleSignUp (e){
    e.preventDefault()
    validation()
    if(company_name === '' || email === '' || password === '') {
      console.log('error');
    }
    else {
      const data = {
        company_name : company_name,
        company_email: email,
        password: password,
        role: 'Admin',
      }
  
      try {
        const response = await fetch(BASE_URL + `company/registerCompany` , {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json',
          },
      })
  
        if (response.ok) {
          const responseBody = await response.json();
              console.log('success', responseBody);
              resetFields()
              setAlertType('success')
              setMessage(responseBody.message)
              sessionStorage.setItem('user-info', JSON.stringify(responseBody));
              setIsPopupOpen(true)
              setTimeout(() => {
                setIsPopupOpen(false)
              } , 3000)
              navigate(`/dashboard`)
        } else {
          const responseBody = await response.json();
          console.log('error', responseBody);
          setAlertType('error')
          setMessage(responseBody.message)
          setIsPopupOpen(true)
          setTimeout(() => {
            setIsPopupOpen(false)
          } , 3000)
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  const resetFields = () => {
    setCompanyName('')
    setEmail('')
    setPassword('')
  }

  const validation = () => {
    const errors = {};
 
    if (email.length === 0) {
      errors.email = "Email is Required";
    } else if (!isEmail(email)) {
      errors.email = "Invalid Email";
    }

    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (password.length === 0) {
      errors.password = 'Password is Required'
    } else if (password.length < 8) {
      errors.password = 'Password should contain atleast 8 charachters'
    } else if (!password.match(upperCase)) {
        errors.password = 'Password should contain uppercase letters!'
    } else if (!password.match(numbers)) {
        errors.password = 'Password should contain numbers!'
    } else if (!password.match(lowerCase)) {
      errors.password = 'Password should contain lowercase letters!'
    } else {
      console.log('password is strong');
      
    }

    if(!(confirmPassword === password)) {
      errors.confirmPassword = 'Password and Confirm Password does not match.'
    }

    if(company_name.length === 0) {
      errors.company_name = 'Company name is Required!'
    } else if(company_name.match(numbers)) {
      errors.company_name = 'Numbers are not allowed in company name.'
    }
    setErrors(errors)

  }

  return (
    <div className='w-screen h-screen'>
      <div className='bg-img flex justify-center items-center py-5 md:p-0'>
        <div className='w-full md:w-[500px] md:h-[500px] backdrop-blur-md bg-white/10 rounded-xl text-center p-5'>
            <h1 className='font-bold text-3xl md:text-4xl text-white'>Sign Up</h1>
            <form className='m-4 flex flex-col md:p-5 gap-5 md:gap-2'>
              <div>
                <input type='text' name='company_name' placeholder='Company Name' value={company_name}
                onChange={(e)=>setCompanyName(e.target.value)}
                className='text-white bg-transparent text-center border-2 p-2 rounded-full outline-0 w-full' 
                />
                <p className='text-red-500 text-left pl-5 pt-1 font-medium'>{errors.company_name}</p>
              </div>
              <div>
                <input type='email' name='email' placeholder='Email' value={email}
                onChange={(e)=>setEmail(e.target.value)}
                className='text-white bg-transparent text-center border-2 p-2 rounded-full outline-0 w-full' 
                />
                <p className='text-red-500 text-left pl-5 pt-1 font-medium'>{errors.email}</p>
              </div>
              <div className=''>
                <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Password' value={password}
                onChange={(e)=>setPassword(e.target.value)}
                className='text-white bg-transparent text-center border-2 p-2 rounded-full outline-0 w-full' 
                />
                <button type='button' className='w-full flex justify-end pr-10 -mt-7 text-white'
                onClick={()=>setShowPassword(!showPassword)}
                >
                {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}/>}
                </button>
                <p className='text-red-500 text-left pl-5 pt-3 -mb-3 font-medium'>{errors.password}</p>
              </div>
              <div className=''>
                <input type={showConfirmPassword ? 'text' : 'password'} name='confirm_password' placeholder='Confirm Password' value={confirmPassword}
                onChange={(e)=>setConfirmPassword(e.target.value)}
                className='text-white bg-transparent text-center border-2 p-2 mt-3 rounded-full outline-0 w-full' 
                />
                <button type='button' className='w-full flex justify-end pr-10 -mt-7 text-white'
                onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                >
                {showConfirmPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}/>}
                </button>
                <p className='text-red-500 text-left pl-5 pt-3 font-medium'>{errors.confirmPassword}</p>
              </div>
              <div className='flex items-center justify-between md:-mt-1'>
                <div className='flex gap-2 items-center'>
                  <input type='checkbox' name='remember' className='w-4 h-4'/>
                  <h5 className='text-white font-medium text-sm md:text-base'>Remember Me</h5>
                </div>
                <div className=''>
                  <h5 className='text-white font-medium text-sm md:text-base'>Forgot Password?</h5>
                </div>
              </div>
              <button className='bg-white p-2 rounded-full font-bold' onClick={(e)=>handleSignUp(e)}>Register</button>
            </form>
            <div className=''>
              <h1 className='text-white'>Already have an account? <span className='font-bold cursor-pointer' onClick={()=>navigate(`/login`)}>Login here</span></h1>
            </div>
        </div>
      </div>
      {
        isPopupOpen && (
          <Popup onClose={() => setIsPopupOpen(false)} alertType={alertType} message={message}/>
        )
      }
  </div>
  )
}

export default SignUp