import { Box, CircularProgress, createTheme, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TablePaginationActions from '../../../base-components/TablePaginationActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { useFormik } from 'formik';
import CustomModal from '../../../base-components/CustomModal';
import Popup from '../../../base-components/Popup';
import { useNavigate } from 'react-router-dom';
// import dayjs from 'dayjs';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const theme = createTheme({
    palette: {
      primary: {
        main: '#033b51'
      },
    },
  });
function Ledgers() {
    const navigate = useNavigate()
    const [ledgers , setLedgers] = useState([])
    // const [ledger , setLedger] = useState({})
    const [ledgerToDelete , setLedgerToDelete] = useState('')
    const [isDeleteModalOpen , setIsDeleteModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page , setPage ] = useState(0)
    const [isSuccessAlert , setIsSuccessAlert] = useState(false)
    const [isFaliureAlert , setIsFailureAlert] = useState(false)
    const [successMessage , setSuccessMessage] = useState('')
    const [failureMessage , setFailureMessage] = useState('')
    const [isPopupOpen , setIsPopupOpen] = useState(false)
    const [loading , setLoading] = useState(false)
    const [role , setRole] = useState('')
    const [location_id , setLocationID] = useState('')
    const [company_id , setCompanyID] = useState('')
    const [member_id , setMemberID] = useState('')
    const [members , setMembers] = useState({})
 
    useEffect(()=> {
      const sessionStr = sessionStorage.getItem('user-info');
      if(sessionStr){
        const session = JSON.parse(sessionStr);
        setRole(session.user.role)
        setCompanyID(session.user.company_id)
        setLocationID(session.user.location_id)
        setMemberID(session.user.member_id)
      }
       else {
        navigate('/login');
      }
},[])

  useEffect(() => {
      getLedgers()
      getMembers()
}, [role]);

function getMembers() {
  let endpoint = '';
  console.log(role);
  if (role === 'SuperAdmin') {
      endpoint = 'members/get-all-members';
  } else if (role === 'Admin') {
      endpoint = `members/getMembersByCompanyID/${company_id}`;
  } else if (role === 'ClubAdmin') {
      endpoint = `members/getMembersByLocationID/${location_id}`;
  }
  if (endpoint) {
      fetch(BASE_URL + endpoint)
          .then((response) => response.json())
          .then((data) => {
            const memberMap = data.members.reduce((acc, member) => {
              acc[member._id] = member.name; // _id is the member_id and name is the member's name
              return acc;
            }, {});
            setMembers(memberMap);
            console.log(memberMap);
            
          })
          .catch((error) => console.log(error));
  }
}

    function getLedgers () {
        setLoading(true);

          let endpoint = '';
          if (role === 'SuperAdmin') {
              endpoint = 'voucher/getAllVouchers';
          } else if (role === 'Admin') {
              endpoint = `voucher/getVouchersByCompanyID/${company_id}`;
          } else if (role === 'ClubAdmin') {
              endpoint = `voucher/getAllVouchersByLocationID/${location_id}`;
          } else if (role === 'User') {
            endpoint = `voucher/getAllVouchersByMemberID/${member_id}`;
          }
        
            fetch(BASE_URL + endpoint)
            .then((response) => response.json())
            .then((data) => {
                setLedgers(data.vouchers)
                setLoading(false); 
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            });
    }

    function deleteLedger () {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        };
        fetch(BASE_URL + `voucher/deleteVoucher/${ledgerToDelete}` , requestOptions)
        .then((response) => response.json())
        .then((data) => {
            getLedgers()
            setIsDeleteModalOpen(false)
            setIsFailureAlert(false)
            setIsSuccessAlert(true)
            setSuccessMessage('Ledger deleted Successfully')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        })
        .catch((error) => {
            console.log(error)
            setIsFailureAlert(true)
            setIsSuccessAlert(false)
            setFailureMessage('Failed to delete Ledger')
            setIsPopupOpen(true)
            setTimeout(() => {
                setIsPopupOpen(false)
            }, 5000)
        });
    }


    function toggleDeleteModal() {
        setIsDeleteModalOpen(!isDeleteModalOpen)
    }

       function showDeleteModal(id) {
        setLedgerToDelete(id);
        setIsDeleteModalOpen(true);
    }
    const paginatedLedgers = !ledgers || ledgers.length === 0 ?
    [] :
     rowsPerPage === -1
    ? ledgers // Show all if "All" option is selected
    : ledgers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

  return (
    <>
        <div className='flex items-center justify-between m-5'>
            <div className='font-bold text-2xl'>Ledgers</div>
        </div>
        {
            loading ? <ThemeProvider theme={theme}><div className='flex justify-center mt-20'><CircularProgress/></div></ThemeProvider> :
            (
            ledgers && ledgers.length !== 0 ? (     
            <Box className="border rounded-lg m-5 animate-fadeIn">
                <Table>
                  <TableHead>
                      <TableRow >
                          <TableCell>Date</TableCell>
                          <TableCell>Invoice No.</TableCell>
                          <TableCell>Member</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Balance</TableCell>
                          <TableCell>Due Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      paginatedLedgers.map((ledger , index) => (
                        <TableRow hover key={index}>
                          <TableCell>{ledger.voucher_date}</TableCell>
                          <TableCell>{ledger.invoice_no}</TableCell>
                          <TableCell>{members[ledger.memberId] || 'Unknown'}</TableCell>
                          <TableCell>{ledger.description}</TableCell>
                          <TableCell>{ledger.amount}</TableCell>
                          <TableCell>{ledger.due_date}</TableCell>
                          <TableCell>
                              <div className={'font-bold p-2 px-4 rounded-full text-center ' + (ledger.isPaid === 'Y' ? 'bg-green-700 text-white' : 'bg-red-700 text-white')}>{ ledger.isPaid === 'Y' ? 'Paid' : 'Pending'}</div>
                          </TableCell>
                          <TableCell>
                              <div className='flex gap-2'>
                                {
                                  role === 'ClubAdmin' || role === 'User' ? (
                                  <div className='font-bold hover:cursor-pointer text-green-700' onClick={()=> navigate(`/voucher/${ledger._id}`)}>
                                    <FontAwesomeIcon icon={faEye}/> View 
                                  </div>
                                  ):null
                                }
                                {
                                  role === 'SuperAdmin' || role === 'Admin' ? (
                                    <div className='flex items-center juctify-center gap-2'>
                                      <div className='font-bold hover:cursor-pointer text-green-700' onClick={()=> navigate(`/voucher/${ledger._id}`)}>
                                          <FontAwesomeIcon icon={faEye}/> View 
                                      </div>
                                      <div className='text-red-700 font-bold hover:cursor-pointer' onClick={()=>{
                                            showDeleteModal(ledger._id);
                                          }}>
                                              <FontAwesomeIcon icon={faTrash}/> Delete 
                                        </div>
                                    </div>
                                        
                                  ) : null
                                }
                              </div>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                      <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={12}
                          count= {ledgers.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          slotProps={{
                              select: {
                              inputProps: {
                                  'aria-label': 'rows per page',
                              },
                              native: true,
                              },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                  </TableFooter>
              </Table>
            </Box>
            ) : (
              <div className='bg-slate-200 w-ful p-3 mt-10 rounded-lg m-10'>
                <h3 className='text-xl font-bold text-center text-slate-500'>No Records Found</h3>
              </div>
            )
        )
          }


      {
        isPopupOpen && (
            <Popup 
             onClose={() => setIsPopupOpen(false)}
             alertType={isSuccessAlert ? 'success' : isFaliureAlert ? 'failure' : ''}
             message={isSuccessAlert ? successMessage : isFaliureAlert ? failureMessage : ''}
             />
                
        )
      }
      
      <CustomModal isModal={isDeleteModalOpen} handleModal={toggleDeleteModal} size='md'>
        <div className='m-5'>
            <h2 className="text-xl font-medium">Delete Ledger?</h2>
            <p className="text-slate-500 mt-3">Are you sure you want to delete this ledger?</p>
            <button className='bg-red-600 p-2 px-5 rounded-lg text-white font-medium hover:bg-red-700 mt-5 float-right mb-5' onClick={deleteLedger}>Delete</button>
        </div>
      </CustomModal>
    </>
  )
}

export default Ledgers